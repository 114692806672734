import srtValidator from 'srt-validator';
import ParseError from 'srt-validator/dist/utils/parse-error';
import { WebVTTParser } from 'webvtt-parser';

export type CaptionValidationError = {
  message: string;
  lineNumber: number;
};

const UPDATED_ERROR_MESSAGES = {
  validatorErrorStartTime: {
    old: 'start time should be less than end time',
    new: 'Start time of subtitle occurs after its end time',
  },
  validatorErrorEndTime: {
    old: 'start time should be less than previous end time',
    new: 'Start time of subtitle occurs after its previous end time',
  },
  validatorErrorSequenceNumberStart: {
    old: 'number of sequence need to start with',
    new: 'Subtitle sequence should begin with',
  },
  validatorErrorSequenceNumberIncrement: {
    old: 'number of sequence need to increment by',
    new: 'Subtitle sequence should increment by',
  },
  parserErrorMissingText: {
    old: 'Missing caption text',
    new: 'Caption text is missing',
  },
  parserErrorMissingSequenceNumber: {
    old: 'Missing sequence number',
    new: 'Sequence number is missing',
  },
  parserErrorInvalidSequenceNumber: {
    old: 'Expected Integer for sequence number:',
    new: 'Expected sequence number instead of',
  },
  parserErrorMissingTimeSpan: {
    old: 'Missing time span',
    new: 'Time span is missing',
  },
  parserErrorInvalidTimeSpan: {
    old: 'Invalid time span:',
    new: 'Time span is invalid:',
  },
  parserErrorInvalidTimeStamp: {
    old: 'Invalid time stamp:',
    new: 'Time stamp is invalid:',
  },
};

export enum SUPPORTED_CAPTION_FORMATS {
  WEBVTT = 'WEBVTT',
  SUBRIP = 'SUBRIP',
}

export const WEBVTT_EXTENSION = '.vtt';
export const WEBVTT_MIME_TYPE = 'text/vtt';

export const SUBRIP_EXTENSION = '.srt';
export const SUBRIP_MIME_TYPE = 'application/x-subrip';

export const extractFormat = (
  file: File
): SUPPORTED_CAPTION_FORMATS | undefined => {
  if (file.type === WEBVTT_MIME_TYPE || file.name.endsWith(WEBVTT_EXTENSION)) {
    return SUPPORTED_CAPTION_FORMATS.WEBVTT;
  }
  if (file.type === SUBRIP_MIME_TYPE || file.name.endsWith(SUBRIP_EXTENSION)) {
    return SUPPORTED_CAPTION_FORMATS.SUBRIP;
  }

  return undefined;
};

export const validateCaptionText = (
  text: string,
  format?: SUPPORTED_CAPTION_FORMATS
): CaptionValidationError[] => {
  // according to this comment https://firstup-io.atlassian.net/browse/FE-1143?focusedCommentId=530572
  // we should support both 00:00:00.000 and 00:00:00,000 formats
  if (format === SUPPORTED_CAPTION_FORMATS.WEBVTT) {
    const parser = new WebVTTParser();
    const tree = parser.parse(text);

    return tree.errors.map((error) => {
      return {
        message: error.message,
        lineNumber: error.line,
      };
    });
  }
  if (format === SUPPORTED_CAPTION_FORMATS.SUBRIP) {
    const newCaptionText = text.replace(
      /(\d{2}:\d{2}:\d{2})\.(\d{3})/g,
      '$1,$2'
    );
    return updateErrorMessages(
      srtValidator(newCaptionText || '') as ParseError[]
    );
  }

  return [
    {
      message: 'Unsupported caption format',
      lineNumber: 1,
    },
  ];
};

function updateErrorMessages(errors: ParseError[]) {
  const result = [] as CaptionValidationError[];
  errors.forEach((msg) => {
    const updatedMessage = { ...msg };
    if (UPDATED_ERROR_MESSAGES[msg.errorCode]) {
      updatedMessage.message = msg.message.replace(
        UPDATED_ERROR_MESSAGES[msg.errorCode].old,
        UPDATED_ERROR_MESSAGES[msg.errorCode].new
      );
    }
    result.push(updatedMessage as CaptionValidationError);
  });
  return result;
}
