import React, { useEffect, useCallback, useState } from 'react';
import { useProgram } from 'contexts/program';
import { Video } from 'models/video';
import { useVideoUploader } from 'hooks/useVideoUploader';
import { Field, Fieldset } from 'shared/Fieldset';
import { FileUploadButton } from 'shared/FileUploadButton';
import { useMutation } from 'react-query';
import {
  UploadCaptionOptions,
  uploadCaptions,
  removeCaptions as removeCaptionsApi,
  RemoveCaptionsOptions,
  UploadCaptionsResponse,
  RemoveCaptionsResponse,
} from 'services/api-captions';
import { useCaptions } from 'hooks/video';
import cx from 'classnames';
import { Trash } from 'shared/icons';
import { TextInput } from 'shared/TextInput';
import { FileDropZone } from 'shared/FileDropZone';
import { Button } from 'DesignSystem/Form';
import { updateVideo } from 'services/api-assets';
import { useFlashMessage } from 'contexts/flasher';
import { UPLOAD, VideoSourceMenu } from '../useVideoSourceMenu';
import { SourceMenu } from '../../SourceMenu';
import { VideoPreview } from './VideoPreview';
import styles from './video.module.css';
import forms from '../../form.module.css';
import { PreviewUploader } from '../PreviewUploader';

type PropsType = {
  video?: Video;
  onChange: (video: Video) => void;
  disableSave?: () => void;
  enableSave?: () => void;
  className?: string;
  sourceMenu?: VideoSourceMenu;
};

export const Upload: React.FC<PropsType> = (props) => {
  const {
    video,
    onChange,
    className,
    sourceMenu,
    disableSave,
    enableSave,
  } = props;

  // keep local state of altText for UI Performance
  const [altText, setAltTextState] = useState<string>(video?.altText ?? '');

  // prevent captions UI from flickering at removal and after videos
  // with captions removed are transcoded
  const [hasCaptionsRemoved, setHasCaptionsRemoved] = useState(false);
  const { id: programId } = useProgram();

  const {
    upload,
    uploadedBytes,
    totalBytes,
    isUploading,
    isTranscoding,
    isCompleted,
    transcodeJobPercentComplete,
    errorMessage,
    onCaptionUpdated,
  } = useVideoUploader({
    programId,
    videoId: video?.id,
    onTranscoding: onChange,
    onSuccess: onChange,
  });

  const { setFlashMessage } = useFlashMessage();

  const handleCaptionUpdated = useCallback(
    (response: UploadCaptionsResponse) => {
      const { video: updatedVideo, errors } = response;

      if (video) {
        video.status = updatedVideo.status;
      }

      if (errors) {
        setFlashMessage({
          severity: 'error',
          message: errors.join('\n'),
        });
      }

      onChange(updatedVideo);
      onCaptionUpdated();
    },
    [onCaptionUpdated, onChange, setFlashMessage, video]
  );

  const {
    mutate: addCaptions,
    isLoading: isCaptionsUploading,
    error: captionsError,
  } = useMutation<UploadCaptionsResponse, Error, UploadCaptionOptions>(
    uploadCaptions,
    {
      onSuccess: handleCaptionUpdated,
    }
  );

  const { mutate: removeCaptions } = useMutation<
    RemoveCaptionsResponse,
    Error,
    RemoveCaptionsOptions
  >(removeCaptionsApi, {
    onSuccess: handleCaptionUpdated,
  });

  const onCaptionSelect = useCallback(
    (file: File) => {
      if (!video) {
        return;
      }

      setHasCaptionsRemoved(false);
      addCaptions({ videoId: video.id, programId, file });
    },
    [addCaptions, video, programId]
  );

  const fetchedCaptions = useCaptions(video).map((c) => c.data?.text);

  const setImage = React.useCallback(
    (image) => {
      if (video) {
        const newVideo = {
          ...video,
          customPreviewImageUrl: image.url,
          sourceType: UPLOAD,
        };
        onChange(newVideo);
        updateVideo({
          programId,
          videoId: video.id,
          previewImageUrl: image.url,
        });
      }
    },
    [onChange, video, programId]
  );

  const setAltText = React.useCallback(
    (text) => {
      setAltTextState(text);
      if (video) {
        const newVideo = {
          ...video,
          altText: text,
          sourceType: UPLOAD,
        };
        onChange(newVideo);
      }
    },
    [onChange, video]
  );

  const isCustomImage = video && video.customPreviewImageUrl !== undefined;

  useEffect(() => {
    if (disableSave && enableSave) {
      if (isUploading) {
        disableSave();
      } else {
        enableSave();
      }
    }
  }, [disableSave, enableSave, isUploading]);

  return (
    <div className={className}>
      <Fieldset className={cx(forms.fieldset, styles.dropzone)}>
        {sourceMenu && (
          <Field label="Source" className={cx(forms.field, styles.videoField)}>
            <div className={styles.sourceFieldContainer}>
              <p className={styles.currentSource}>Upload</p>
              <SourceMenu menu={sourceMenu.menu} />
            </div>
          </Field>
        )}
        <Field label="File" className={cx(forms.field, styles.videoField)}>
          <FileUploadButton
            onFileSelect={upload}
            accept="video/mp4,video/x-m4v,video/*"
            type="secondary"
            iconName="Upload"
            iconType="SVG"
            compact
          >
            Upload
          </FileUploadButton>
          {errorMessage && <div className={styles.error}>{errorMessage}</div>}
        </Field>
        <Field label="Video" className={cx(forms.field, styles.videoField)}>
          <FileDropZone
            accept="video/mp4,video/x-m4v,video/*"
            onFileSelect={upload}
            withButton={false}
          >
            <VideoPreview
              uploadedBytes={uploadedBytes}
              totalBytes={totalBytes}
              isUploading={isUploading}
              isTranscoding={isTranscoding}
              isCompleted={isCompleted}
              transcodeJobPercentComplete={transcodeJobPercentComplete}
              video={video}
            />
          </FileDropZone>
        </Field>
        <Field
          label="Cover Image"
          className={cx(
            forms.field,
            styles.videoField,
            styles.videoPreviewUploader
          )}
        >
          <PreviewUploader
            video={video}
            setImage={setImage}
            shouldClear={!isCustomImage}
          />
          {isCustomImage && (
            <button
              type="button"
              className={styles.removeImage}
              onClick={() => setImage({ url: '' })}
            >
              <Trash />
            </button>
          )}
        </Field>
        <Field label="Alt Text" className={cx(forms.field, styles.videoField)}>
          <TextInput
            value={altText}
            onChange={setAltText}
            className={styles.input}
            autoComplete="off"
            disabled={!video?.previewImageUrl && !video?.customPreviewImageUrl}
          />
        </Field>
        <Field label="Captions" className={cx(forms.field, styles.videoField)}>
          {isCompleted &&
            !isCaptionsUploading &&
            !hasCaptionsRemoved &&
            fetchedCaptions.map(
              (caption) =>
                caption && (
                  <textarea
                    value={caption}
                    rows={10}
                    className={styles.captionTextarea}
                    readOnly
                  />
                )
            )}
          {isCaptionsUploading && <p>Uploading...</p>}
          <div className={styles.captionButtons}>
            <FileUploadButton
              onFileSelect={onCaptionSelect}
              type="secondary"
              compact
              disabled={!video?.id || isCaptionsUploading}
              accept="application/x-subrip,text/*,.vtt,.srt"
              shouldClear={hasCaptionsRemoved}
            >
              Upload Captions
            </FileUploadButton>
            {video &&
              isCompleted &&
              !isCaptionsUploading &&
              !hasCaptionsRemoved &&
              fetchedCaptions.length > 0 && (
                <Button
                  secondary
                  compact
                  label="Remove Captions"
                  onClick={() => {
                    setHasCaptionsRemoved(true);
                    removeCaptions({ programId, videoId: video.id });
                  }}
                />
              )}
          </div>
          {captionsError && (
            <div className={styles.error}>{captionsError.message}</div>
          )}
        </Field>
      </Fieldset>
    </div>
  );
};
