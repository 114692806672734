import React from 'react';
import { useMutation } from 'react-query';
import { useCaptions, useVideoUpdate } from 'hooks/video';
import { useProgram } from 'contexts/program';
import { useDesign } from 'contexts/design';
import { VideoFieldData } from 'models/donkey';
import { videoToField } from 'models/publisher/block';
import {
  FetchedCaption,
  updateCaptions as updateCaptionsApi,
  UpdateCaptionsOptions,
  UpdateCaptionsResponse,
} from 'services/api-captions';
import { UpdateVideoCaptionStylesProps } from 'services/api-assets';
import { FieldFormProps } from '../../../useFieldForm';
import { useFetchVideoFromFeed } from './useFetchVideoFromFeed';
import { CaptionSettings } from '../components/VideoCaptions/CaptionSettings';
import { useCaptionsJobs } from './useCaptionsJobs';
import { UpdateCaptions } from './useCaptionSettings';

export const useVideoCaption: ({
  fieldData,
  onChange,
  onCaptionUpdated,
}: {
  fieldData: VideoFieldData;
  onChange: FieldFormProps<VideoFieldData>['onChange'];
  onCaptionUpdated: () => void;
}) => {
  fetchedCaptions: FetchedCaption[];
  captionsError: string | undefined;
  onListItemEdit: (index: number) => void;
  onListItemRemove: (index: number) => void;
  openSettings: () => void;
  isDisabled: boolean;
  CaptionSettings: JSX.Element | null;
  defaultShowCaptions: boolean;
  toggleDefaultShowCaptions: () => void;
  changeCaptionStyles: (captionStyles: UpdateVideoCaptionStylesProps) => void;
  isCaptionsUpdating: boolean;
} & UpdateVideoCaptionStylesProps = ({
  fieldData,
  onChange,
  onCaptionUpdated,
}) => {
  const { transcriptionJob, translationJob } = useCaptionsJobs();
  const { id: programId } = useProgram();
  const { active: isDesignAsset } = useDesign();

  const { videoFromFeed, refetchVideo } = useFetchVideoFromFeed(
    fieldData.video_id,
    isDesignAsset
  );

  const [defaultShowCaptions, setDefaultShowCaptions] = React.useState<boolean>(
    true
  );
  React.useEffect(() => {
    // Default to true only if the value is undefined
    setDefaultShowCaptions(videoFromFeed?.defaultShowCaptions ?? true);
  }, [videoFromFeed?.defaultShowCaptions]);

  const valueOrDefault = <T extends string | number>(
    value: T | undefined,
    defaultValue: T
  ) => {
    if (!value) {
      return defaultValue;
    }

    if (value === '') {
      return defaultValue;
    }

    return value;
  };

  const { mutate: updateVideo } = useVideoUpdate({
    programId,
    videoId: fieldData.video_id ?? 0,
    isDesignAsset,
    onSuccess: () => refetchVideo(),
    onError: () => setDefaultShowCaptions(!defaultShowCaptions),
  });

  const toggleDefaultShowCaptions = () => {
    setDefaultShowCaptions(!defaultShowCaptions);
    updateVideo({
      defaultShowCaptions: !defaultShowCaptions,
    });
  };

  const handleCaptionUpdated = React.useCallback(
    (response: UpdateCaptionsResponse) => {
      refetchVideo();
      onChange(videoToField(response.video));
      onCaptionUpdated();
    },
    [onCaptionUpdated, onChange, refetchVideo]
  );

  const {
    mutate: mutateCaptions,
    isLoading: isCaptionsUpdating,
    error: captionsError,
  } = useMutation<UpdateCaptionsResponse, Error, UpdateCaptionsOptions>(
    updateCaptionsApi,
    {
      onSuccess: handleCaptionUpdated,
    }
  );

  const captionQueries = useCaptions(videoFromFeed);
  const fetchedCaptions = React.useMemo(() => {
    const memo: Record<string, FetchedCaption> = {};

    captionQueries.forEach((query) => {
      if (query.data) memo[query.data.language] = query.data;
    });
    return Object.values(memo);
  }, [captionQueries]);

  React.useEffect(() => {
    if (
      (transcriptionJob?.status === 'completed' && transcriptionJob?.caption) ||
      (translationJob?.status === 'completed' && translationJob?.captions)
    ) {
      refetchVideo();
    }
  }, [
    refetchVideo,
    transcriptionJob?.caption,
    transcriptionJob?.status,
    translationJob?.captions,
    translationJob?.status,
  ]);

  const updateCaptions = React.useCallback<UpdateCaptions>(
    ({ toAdd, toRemove, onSuccess, onError }) => {
      if (!toAdd && !toRemove) return;
      if (!videoFromFeed) return;

      mutateCaptions(
        {
          programId,
          isDesignAsset,
          videoId: videoFromFeed.id,
          toAdd,
          toRemove,
        },
        {
          onSuccess,
          onError,
        }
      );
    },
    [isDesignAsset, mutateCaptions, programId, videoFromFeed]
  );

  const [showSettings, setShowSettings] = React.useState<boolean>(false);

  const [selectedCaption, setSelectedCaption] = React.useState<
    FetchedCaption | undefined
  >(undefined);

  const onListItemEdit = React.useCallback(
    (index: number) => {
      setSelectedCaption(fetchedCaptions[index]);
      setShowSettings(true);
    },
    [fetchedCaptions]
  );

  const onListItemRemove = React.useCallback<
    (
      index: number,
      options?: { onSuccess?: () => void; onError?: (error: Error) => void }
    ) => void
  >(
    (index: number, { onSuccess, onError } = {}) => {
      const { url } = fetchedCaptions[index];
      updateCaptions({ toRemove: url, onSuccess, onError });
    },
    [fetchedCaptions, updateCaptions]
  );

  const isDisabled = videoFromFeed?.sourceType !== 'admin_created';

  const openSettings = React.useCallback(() => {
    // Captions cannot be applied to external videos

    if (isDisabled || showSettings) return;
    setShowSettings(true);
  }, [isDisabled, showSettings]);

  const closeSettings = React.useCallback(() => {
    setShowSettings(false);
    setSelectedCaption(undefined);
  }, []);

  const Component = React.useMemo(
    () =>
      showSettings ? (
        <CaptionSettings
          fieldData={fieldData}
          isDesignAsset={isDesignAsset}
          onClose={closeSettings}
          updateCaptions={updateCaptions}
          isCaptionsUpdating={isCaptionsUpdating}
          currentCaption={selectedCaption}
        />
      ) : null,
    [
      closeSettings,
      fieldData,
      isCaptionsUpdating,
      isDesignAsset,
      selectedCaption,
      showSettings,
      updateCaptions,
    ]
  );

  return {
    fetchedCaptions,
    captionsError: captionsError?.message,
    onListItemEdit,
    onListItemRemove,
    openSettings,
    CaptionSettings: Component,
    isDisabled,
    defaultShowCaptions,
    toggleDefaultShowCaptions,
    changeCaptionStyles: updateVideo,
    captionsColor: valueOrDefault(videoFromFeed?.captionsColor, '#FFF'),
    captionsColorBackground: valueOrDefault(
      videoFromFeed?.captionsColorBackground,
      '#000'
    ),
    captionsFontSize: valueOrDefault(videoFromFeed?.captionsFontSize, 100),
    captionsPosition: valueOrDefault(videoFromFeed?.captionsPosition, 'bottom'),
    isCaptionsUpdating,
  };
};
