import React from 'react';
import { useProgram } from 'contexts/program';
import { useVideoCaptionValidator } from 'hooks/useVideoCaptionValidator';
import { uniqueId } from 'hooks/useUniqueId';
import {
  FetchedCaption,
  TranscriptionJob,
  TranslationJob,
} from 'services/api-captions';
import {
  SUBRIP_TIMING,
  WEBVTT_TIMING,
} from '../utils/caption-format-converter';
import {
  CaptionValidationError,
  extractFormat,
  SUBRIP_EXTENSION,
  SUBRIP_MIME_TYPE,
  validateCaptionText,
  WEBVTT_EXTENSION,
  WEBVTT_MIME_TYPE,
} from '../utils/caption-format-validator';
import {
  useCaptionsJobs,
  useTranscribeVideo,
  useTranslateVideo,
} from './useCaptionsJobs';

export type UpdateCaptions = (params: {
  toAdd?: File | File[];
  toRemove?: string;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
}) => void;

export type CaptionsSubmittedSuccessProps = {
  uploaded?: boolean;
  edited?: boolean;
  deleted?: boolean;
  transcribed?: boolean;
  translated?: boolean;
};

export enum CaptionsType {
  GENERATE = 'generate',
  UPLOAD = 'upload',
}

type UseCaptionSettings = (props: {
  updateCaptions: UpdateCaptions;
  isCaptionsUpdating: boolean;
  currentCaption?: FetchedCaption;
  videoId?: number;
  isDesignAsset?: boolean;
}) => {
  onFormSubmit: (options?: {
    onSuccess?: (props: CaptionsSubmittedSuccessProps) => void;
    onError?: (errors: Error[] | CaptionValidationError[]) => void;
  }) => void;
  captionText?: string;
  onCaptionTextChange: (captionText: string) => void;
  onReplaceClick: () => void;
  onRemoveClick: () => void;
  onUploadClick: () => void;
  fileInput: React.ReactNode;
  errorMessage?: string;
  isDisabled: boolean;
  validationErrors?: { [key: string]: CaptionValidationError[] };
  isTranscribing: boolean;
  isTranslating: boolean;
  captionsType: CaptionsType | null;
  setCaptionsType: (captionsType: CaptionsType | null) => void;
  autoTranslateCaptions: boolean;
  setAutoTranslateCaptions: (value: boolean) => void;
  selectedLanguages: string[];
  setSelectedLanguages: (languages: string[]) => void;
  selectedCaptionFiles: File[];
  onRemoveCaptionClick: (files: File) => void;
};

export const useCaptionSettings: UseCaptionSettings = ({
  updateCaptions,
  isCaptionsUpdating,
  currentCaption,
  videoId,
  isDesignAsset,
}) => {
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const isFileEditRef = React.useRef<boolean>(false);
  const toAddRef = React.useRef<File | undefined>(undefined);
  const toRemoveRef = React.useRef<string | undefined>(undefined);

  const onUploadClick = () => fileInputRef.current?.click();
  const [captionText, setCaptionText] = React.useState<string | undefined>(
    currentCaption?.text
  );

  const [selectedCaptionFiles, setSelectedCaptionFiles] = React.useState<
    File[]
  >([]);

  const [validationErrors, setValidationErrors] = React.useState<{
    [key: string]: CaptionValidationError[];
  }>({});

  const validCaptionFiles = React.useMemo(() => {
    return selectedCaptionFiles.filter(
      (file) => !validationErrors[file.name]?.length
    );
  }, [selectedCaptionFiles, validationErrors]);

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();
  const [isDisabled, setIsDisabled] = React.useState<boolean>(true);

  const onReplaceClick = () => {
    onUploadClick();
  };

  const onRemoveClick = () => {
    if (currentCaption) toRemoveRef.current = currentCaption.url;
    toAddRef.current = undefined;
    setCaptionText(undefined);
    setValidationErrors({});
    setErrorMessage(undefined);
    setIsDisabled(typeof toRemoveRef.current === 'undefined');
  };

  const onCaptionTextChange = async (text: string) => {
    setValidationErrors({});
    setErrorMessage(undefined);

    if (text === captionText) {
      toAddRef.current = undefined;
      if (currentCaption) {
        // Only evaluate if editing an existing caption.
        // Editing an uploaded caption should not disable the form.
        setIsDisabled(typeof toRemoveRef.current === 'undefined');
      }
      return;
    }

    const file = createCaptionFile(text);
    if (file) {
      handleNewFile(file, true);
    }

    setIsDisabled(
      typeof toAddRef.current === 'undefined' &&
        typeof toRemoveRef.current === 'undefined'
    );
  };

  const onRemoveCaptionClick = React.useCallback(
    (file: File) => {
      const updatedCaptionFiles = selectedCaptionFiles.filter(
        (selectedFile) => selectedFile.name !== file.name
      );
      const updatedValidationErrors = { ...validationErrors };
      delete updatedValidationErrors[file.name];

      setValidationErrors(updatedValidationErrors);
      setSelectedCaptionFiles(updatedCaptionFiles);
      setIsDisabled(updatedCaptionFiles.length === 0);
    },
    [selectedCaptionFiles, validationErrors]
  );

  const { validate } = useVideoCaptionValidator();

  const [captionsType, setCaptionsType] = React.useState<CaptionsType | null>(
    null
  );

  const [autoTranslateCaptions, setAutoTranslateCaptions] = React.useState(
    false
  );

  const [selectedLanguages, setSelectedLanguages] = React.useState<string[]>(
    []
  );

  const { id: programId } = useProgram();

  const {
    isCaptionTranscriptionEnabled,
    setTranscriptionJob,
    setTranslationJob,
  } = useCaptionsJobs();

  const isMultiFileUploadEnabled = isCaptionTranscriptionEnabled;

  const { startTranscription, isTranscribing } = useTranscribeVideo({
    onSuccess: setTranscriptionJob,
  });

  const { startTranslation, isTranslating } = useTranslateVideo({
    onSuccess: setTranslationJob,
    languages: selectedLanguages,
  });

  React.useEffect(() => {
    if (currentCaption) {
      setCaptionsType(null);
    } else if (isCaptionTranscriptionEnabled) {
      setCaptionsType(CaptionsType.GENERATE);
    } else {
      setCaptionsType(CaptionsType.UPLOAD);
    }
  }, [currentCaption, isCaptionTranscriptionEnabled]);

  const handleNewFile = React.useCallback(
    (file: File, isEdit: boolean) => {
      const { isValid, errors } = validate({ file });
      if (isValid) {
        toAddRef.current = file;
        isFileEditRef.current = isEdit;
        return;
      }

      if (errors.length) {
        setErrorMessage(`${file.name}: ${errors.join(', ')}`);
      }
    },
    [validate]
  );

  const onInputChange = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      setValidationErrors({});
      setErrorMessage(undefined);

      let newCaptionValidationErrors: CaptionValidationError[] = [];
      const file = event.currentTarget.files?.[0];
      if (file) {
        handleNewFile(file, false);
        const newCaptionText = await file.text();
        newCaptionValidationErrors = validateCaptionText(
          newCaptionText,
          extractFormat(file)
        );
        setValidationErrors({ [file.name]: newCaptionValidationErrors });
        setCaptionText(newCaptionText);
      }
      setIsDisabled(
        (typeof toAddRef.current === 'undefined' &&
          typeof toRemoveRef.current === 'undefined') ||
          !!newCaptionValidationErrors.length
      );

      if (fileInputRef.current) fileInputRef.current.value = '';
    },
    [handleNewFile]
  );

  const onMultiFileInputChange = React.useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      const multiSelectedFiles: File[] = [];
      const multiSelectedValidationErrors: {
        [key: string]: CaptionValidationError[];
      } = {};

      const updatedCaptionFiles = Array.from(event.currentTarget.files || []);
      for (const file of updatedCaptionFiles) {
        let newCaptionValidationErrors: CaptionValidationError[] = [];
        // eslint-disable-next-line no-await-in-loop
        const newCaptionText = await file.text();
        newCaptionValidationErrors = validateCaptionText(
          newCaptionText,
          extractFormat(file)
        );

        if (updatedCaptionFiles.length === 1) {
          setCaptionText(newCaptionText);
        }

        multiSelectedFiles.push(file);
        multiSelectedValidationErrors[file.name] = newCaptionValidationErrors;
      }

      setSelectedCaptionFiles(multiSelectedFiles);
      setValidationErrors(multiSelectedValidationErrors);

      setIsDisabled(multiSelectedFiles.length === 0);
    },
    []
  );

  const createCaptionFile = (editedCaptionText: string) => {
    if (!editedCaptionText || editedCaptionText === captionText)
      return undefined;

    const fileInfo = {
      extension: '.txt',
      mimeType: '',
    };
    if (WEBVTT_TIMING.test(editedCaptionText)) {
      fileInfo.extension = WEBVTT_EXTENSION;
      fileInfo.mimeType = WEBVTT_MIME_TYPE;
    } else if (SUBRIP_TIMING.test(editedCaptionText)) {
      fileInfo.extension = SUBRIP_EXTENSION;
      fileInfo.mimeType = SUBRIP_MIME_TYPE;
    }

    const filename = `edited_caption_${uniqueId()}${
      currentCaption ? `_${currentCaption.language}` : ''
    }${fileInfo.extension}`;

    return new File([editedCaptionText], filename, {
      type: fileInfo.mimeType,
    });
  };

  const generateCaptions = React.useCallback(
    ({ onSuccess, onError } = {}) => {
      const generationPromises: Promise<
        TranscriptionJob | TranslationJob
      >[] = [];
      const successProps: CaptionsSubmittedSuccessProps = {};

      generationPromises.push(
        new Promise((resolve, reject) => {
          startTranscription(
            {
              programId,
              videoId,
              isDesignAsset,
            },
            {
              onSuccess: (job) => {
                successProps.transcribed = true;
                resolve(job);
              },
              onError: reject,
            }
          );
        })
      );

      if (selectedLanguages.length > 0) {
        generationPromises.push(
          new Promise((resolve, reject) => {
            startTranslation(
              {
                programId,
                videoId,
                isDesignAsset,
                languages: selectedLanguages,
              },
              {
                onSuccess: (job) => {
                  successProps.translated = true;
                  resolve(job);
                },
                onError: reject,
              }
            );
          })
        );
      }

      Promise.allSettled(generationPromises).then((results) => {
        const errors: Error[] = [];
        results.forEach(
          (result) =>
            result.status === 'rejected' &&
            errors.push(new Error(result.reason))
        );
        if (errors.length) {
          onError?.(errors);
        } else {
          onSuccess?.(successProps);
        }
      });
    },
    [
      isDesignAsset,
      programId,
      selectedLanguages,
      startTranscription,
      startTranslation,
      videoId,
    ]
  );

  const onFormSubmit = React.useCallback<
    (options?: {
      onSuccess?: (props: CaptionsSubmittedSuccessProps) => void;
      onError?: (errors: Error[] | CaptionValidationError[]) => void;
    }) => void
  >(
    async ({ onSuccess, onError } = {}) => {
      if (captionsType === CaptionsType.GENERATE) {
        await generateCaptions({ onSuccess, onError });
        return;
      }

      if (!isMultiFileUploadEnabled) {
        // Using refs for this check because the input change callback won't
        // wait for react state changes.
        const noChange = !toAddRef.current && !toRemoveRef.current;
        const hasErrors = !!errorMessage || !!validationErrors?.length;
        if (noChange || hasErrors) return;

        if (toAddRef.current && isFileEditRef.current) {
          // Validate edits before submission
          const newCaptionText = await toAddRef.current.text();
          const newCaptionValidationErrors = validateCaptionText(
            newCaptionText,
            extractFormat(toAddRef.current)
          );
          setValidationErrors({
            [toAddRef.current.name]: newCaptionValidationErrors,
          });

          if (newCaptionValidationErrors?.length) {
            onError?.(newCaptionValidationErrors);
            return;
          }
        }
      }
      updateCaptions({
        toAdd:
          isMultiFileUploadEnabled && !toAddRef.current
            ? validCaptionFiles
            : toAddRef.current,
        toRemove: currentCaption?.url,
        onSuccess: () => {
          onSuccess?.({
            uploaded: isMultiFileUploadEnabled
              ? validCaptionFiles.length > 0
              : !!toAddRef.current,
            edited: isFileEditRef.current,
            deleted: !!toRemoveRef.current,
          });
        },
        onError: (error) => onError?.([error]),
      });
    },
    [
      captionsType,
      currentCaption?.url,
      errorMessage,
      generateCaptions,
      isMultiFileUploadEnabled,
      updateCaptions,
      validCaptionFiles,
      validationErrors?.length,
    ]
  );

  const fileInput = React.useMemo(
    () => (
      <input
        ref={fileInputRef}
        type="file"
        style={{ display: 'none' }}
        accept={`${SUBRIP_MIME_TYPE},${SUBRIP_EXTENSION},${WEBVTT_EXTENSION}`}
        disabled={isCaptionsUpdating}
        onChange={
          isMultiFileUploadEnabled ? onMultiFileInputChange : onInputChange
        }
        multiple={isMultiFileUploadEnabled}
      />
    ),
    [
      isCaptionsUpdating,
      isMultiFileUploadEnabled,
      onInputChange,
      onMultiFileInputChange,
    ]
  );

  return {
    onFormSubmit,
    captionText,
    onCaptionTextChange,
    onReplaceClick,
    onRemoveClick,
    onUploadClick,
    fileInput,
    errorMessage,
    validationErrors,
    isDisabled,
    isTranscribing,
    isTranslating,
    captionsType,
    setCaptionsType,
    autoTranslateCaptions,
    setAutoTranslateCaptions,
    selectedLanguages,
    setSelectedLanguages,
    selectedCaptionFiles,
    onRemoveCaptionClick,
    isMultiFileUploadEnabled,
  };
};
