import * as React from 'react';
import {
  DataBlock,
  DefinitionBlock,
  FieldData,
  StyleData,
  FormatData,
} from 'models/publisher/block';
import { useStateSyncer } from 'hooks/useStateSyncer';
import { uniqueId, useUniqueId } from 'hooks/useUniqueId';
import { PublisherMode, usePublisher } from 'contexts/publisher';
import { resolveBlocks } from 'services/api-content-blocks';
import { useProgram } from 'contexts/program';
import { Instance, Instances } from 'models/publisher/post';
import { Layout } from 'models/publisher/format';
import {
  LibraryCategory,
  omittedLibraryCategories,
  PublisherType,
} from 'models/library';
import { useDesign } from 'contexts/design';

async function handleUpdate(
  id: string,
  record: Instance,
  data: Partial<DataBlock>
) {
  if (record.id !== id) return record;
  return { id, block: { ...record.block, ...data } };
}

async function makeBlockDefinitions(
  blockDatas: DataBlock[],
  program_id: number,
  done: (blocks: DefinitionBlock[]) => void
) {
  // TODO This blocks until the HTTP request finishes,
  //      often with a jittery UI as a result.
  const data = await resolveBlocks(program_id, blockDatas);
  done(data);
}

// this is needed to have a fresh state of "instances" in the callbacks
// otherwise the state is stale, which causes bugs when trying to update
// more than 1 field at the same time
function useExtendedState<T>(initialState: T) {
  const [state, setState] = React.useState<T>(initialState);
  const getLatestState = () => {
    return new Promise<T>((resolve) => {
      setState((s) => {
        resolve(s);
        return s;
      });
    });
  };

  return [state, setState, getLatestState] as const;
}

export function useBlocksEditor(
  initialBlocks: DefinitionBlock[],
  onChange?: (blocks: DefinitionBlock[]) => void,
  publisherType: PublisherType = PublisherType.campaigns
): {
  droppableId: string;
  publisherMode: PublisherMode;
  omitLibraryCategories: LibraryCategory['identifier'][];
  instances: Instances;
  selected: Instances[number] | undefined;
  select: (id: string) => void;
  insert: (blocks: DataBlock[], atIndex?: number) => void;
  move: (fromIndex: number, toIndex: number) => void;
  remove: (blockId: string) => void;
  updateFieldsData: (blockId: string, data: FieldData) => void;
  updateFormatData: (blockId: string, data: FormatData) => void;
  updateStyleData: (blockId: string, data: StyleData) => void;
  updateTarget: (blockId: string, data: DefinitionBlock['target']) => void;
  publisherType: PublisherType;
} {
  const droppableId = useUniqueId();
  const publisher = usePublisher();
  const design = useDesign();
  const { id: program_id } = useProgram();

  const [instances, setInstances, getLatestInstances] = useExtendedState(
    initialBlocks.map((block) => ({
      id: uniqueId(),
      block,
    }))
  );

  const { sync } = useStateSyncer<Instances>(
    `publisher-content-${publisher.id}-block-instances`,
    (incomingInstances) => setInstances(incomingInstances)
  );

  const [selectedId, setSelectedId] = React.useState('');

  const selected = React.useMemo(
    () => instances.find((i) => i.id === selectedId),
    [instances, selectedId]
  );

  const publisherMode = React.useMemo(() => {
    const blocks = instances.reduce<DefinitionBlock[]>(
      (memo, { block }) =>
        block.name !== 'email_link' ? [...memo, block] : memo,
      []
    );
    const isFullHtmlTemplate =
      blocks.length === 1 &&
      blocks[0].name === 'custom_html' &&
      blocks[0].format_data?.layout === Layout.FullWidth;

    return isFullHtmlTemplate ? PublisherMode.htmlFull : PublisherMode.standard;
  }, [instances]);

  const propagateChange = React.useCallback(
    (changed: Instances) => {
      setInstances(changed);
      if (publisher.id !== 'new') sync(changed);
      if (onChange) onChange(changed.map((instance) => instance.block));
    },
    [setInstances, publisher.id, sync, onChange]
  );

  React.useEffect(() => {
    // This effect only exists for when a block is being loaded
    // from the server. If there are any instances already, then
    // ignore whatever comes in from the publisher. The publisher
    // gets its changes from either this context, or the server
    // when loading by ID. This effect is effectively replacing
    // its own state with that from the server when loading.
    // There  might be a cleaner way to handle this scenario,
    // so that there isn't a ciruclar dependency, but this  "if"
    // condition works for now.
    // --
    // Only sets the instances when going from [] -> [ ...stuff... ],
    // otherwise uses it's own internal copy.
    if (
      !instances.length &&
      publisherType === PublisherType.journeys &&
      design.design.blocks.length
    ) {
      setInstances(
        design.design.blocks.map((block) => ({
          id: uniqueId(),
          block, // we shouldn't preprocess these blocks
        }))
      );
    }
    if (!instances.length && publisher.post.blocks.length)
      setInstances(
        publisher.post.blocks.map((block) => ({
          id: uniqueId(),
          block, // we shouldn't preprocess these blocks
        }))
      );
    /*
     The `instances` dependency is purposefully excluded here. `propagateChange`
     is responsible for updating both the `instances` state and passing the change
     up. When we deleted a block, resulting in an empty instances, this effect would run
     and immediately reset instances to the publisher blocks, which hadn't been affected
     by the deletion yet.
     Removing instances from the dependencies prevents that's from happening,
     and the intended behavior (setting instances on load from serve, if instances
     is empty) is maintained
    */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [publisher.post.blocks, design.design.blocks]);

  return {
    droppableId,
    publisherMode,
    omitLibraryCategories: [...omittedLibraryCategories[publisherType]],
    instances,
    selected,
    select: setSelectedId,
    insert: React.useCallback(
      (blocks: DataBlock[], atIndex = instances.length) => {
        makeBlockDefinitions(blocks, program_id, (definitions) => {
          propagateChange([
            ...instances.slice(0, atIndex),
            ...definitions.map((definition) => ({
              id: uniqueId(),
              block: definition,
            })),
            ...instances.slice(atIndex),
          ]);
        });
      },
      [instances, propagateChange, program_id]
    ),
    move: React.useCallback(
      (fromIndex: number, toIndex: number) => {
        const others = [
          ...instances.slice(0, fromIndex),
          ...instances.slice(fromIndex + 1),
        ];
        propagateChange([
          ...others.slice(0, toIndex),
          instances[fromIndex],
          ...others.slice(toIndex),
        ]);
      },
      [instances, propagateChange]
    ),
    remove: React.useCallback(
      (blockId: string) => {
        propagateChange(instances.filter((block) => block.id !== blockId));
      },
      [instances, propagateChange]
    ),
    updateFieldsData: React.useCallback(
      async (blockId: string, data: FieldData) => {
        const resolvedInstance = await getLatestInstances();
        const changes: Instances = await Promise.all(
          resolvedInstance.map(async (block_instance) =>
            handleUpdate(blockId, block_instance, { field_data: data })
          )
        );
        propagateChange(changes);
      },
      [getLatestInstances, propagateChange]
    ),
    updateFormatData: React.useCallback(
      async (blockId: string, data: FormatData) => {
        const resolvedInstance = await getLatestInstances();
        const changes: Instances = await Promise.all(
          resolvedInstance.map(async (block_instance) =>
            handleUpdate(blockId, block_instance, { format_data: data })
          )
        );
        propagateChange(changes);
      },
      [getLatestInstances, propagateChange]
    ),
    updateStyleData: React.useCallback(
      async (blockId: string, data: StyleData) => {
        const resolvedInstance = await getLatestInstances();
        const changes: Instances = await Promise.all(
          resolvedInstance.map(async (block_instance) =>
            handleUpdate(blockId, block_instance, { style_data: data })
          )
        );
        propagateChange(changes);
      },
      [getLatestInstances, propagateChange]
    ),
    updateTarget: React.useCallback(
      async (blockId: string, data: DefinitionBlock['target']) => {
        const resolvedInstance = await getLatestInstances();
        const changes: Instances = await Promise.all(
          resolvedInstance.map(async (block_instance) =>
            handleUpdate(blockId, block_instance, { target: data })
          )
        );
        propagateChange(changes);
      },
      [getLatestInstances, propagateChange]
    ),
    publisherType,
  };
}

export const BlocksEditorContext = React.createContext({
  instances: [],
  selected: undefined,
  select: () => {},
  insert: () => {},
  move: () => {},
  remove: () => {},
  updateFieldsData: () => {},
  updateFormatData: () => {},
  updateStyleData: () => {},
  updateTarget: () => {},
  droppableId: '',
  publisherMode: PublisherMode.standard,
  omitLibraryCategories: [],
  publisherType: PublisherType.campaigns,
} as ReturnType<typeof useBlocksEditor>);
