const IDENTIFIER = /^\d+/;
export const WEBVTT_TIMING = /(?:\d{2}:)?\d{2}:\d{2}\.\d{3}\s+-->\s+(?:\d{2}:)?\d{2}:\d{2}\.\d{3}/;
export const SUBRIP_TIMING = /\d{2}:\d{2}:\d{2},\d{3}\s-->\s\d{2}:\d{2}:\d{2},\d{3}/;
const PAYLOAD = /(?:.|\n)+?(?=\n{2})|(?:.|\n)+$/;

const WEBVTT_MATCHER = new RegExp(
  `(?<identifier>${IDENTIFIER.source})\n(?<timing>${WEBVTT_TIMING.source}).*?\n(?<payload>${PAYLOAD.source})`,
  'gm'
);
const SUBRIP_MATCHER = new RegExp(
  `(?<identifier>${IDENTIFIER.source})\n(?<timing>${SUBRIP_TIMING.source})\n(?<payload>${PAYLOAD.source})`,
  'gm'
);

const DEFAULT_WEBVTT_TIMING = '00:00:00.000 --> 00:00:00.000';
const DEFAULT_SUBRIP_TIMING = '00:00:00,000 --> 00:00:00,000';

export const vttToSrt = (text: string): string => {
  const cues = Array.from(text.matchAll(WEBVTT_MATCHER)).map((match) => {
    const vttTiming = match.groups?.timing ?? DEFAULT_WEBVTT_TIMING;
    const srtTiming = vttTiming
      .split(/\s+-->\s+/)
      .map((time) => time.padStart(12, '00:'))
      .join(' --> ')
      .replaceAll('.', ',');
    return `${match.groups?.identifier}\n${srtTiming}\n${match.groups?.payload}`;
  });
  return cues.join('\n\n');
};

export const srtToVtt = (text: string): string =>
  Array.from(text.matchAll(SUBRIP_MATCHER))
    .map((match) => {
      const srtTiming = match.groups?.timing ?? DEFAULT_SUBRIP_TIMING;
      const vttTiming = srtTiming.replaceAll(',', '.');
      return `${match.groups?.identifier}\n${vttTiming}\n${match.groups?.payload}`;
    })
    .join('\n\n');
