import { useProgram } from 'contexts/program';
import { AboutPage } from 'models/about-page';
import { LandingPageTabType, Topic, TopicShortcut } from 'models/topic';
import { User } from 'models/user';
import {
  useInfiniteQuery,
  useMutation,
  useQueries,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import {
  archiveTopic,
  bulkArchiveTopics,
  bulkUnarchiveTopics,
  createTopic,
  createTopicShortcut,
  deleteTopicShortcut,
  FetchParams,
  fetchTopContributors,
  fetchTopicAboutPage,
  fetchTopicById,
  fetchTopicFollowersCount,
  FetchTopicFollowersCountResponse,
  fetchTopics,
  fetchTopicShortcuts,
  initializeTopic,
  reorderTopicShortcuts,
  TopicsCollectionData,
  unarchiveTopic,
  editTopic,
  updateTopic,
  updateTopicAboutPage,
  updateTopicShortcut,
  publishTopic,
  deleteDraft,
} from '../services/api-topics';
import {
  BulkSelection,
  InfiniteQueryResponse,
  MutationOptions,
  nextPageToFetch,
  QueryResponse,
  TopicBulkActionFilters,
} from './common';

export type TopicQuery = {
  data?: Topic;
  isLoading: boolean;
};

export function mapServerDataToTopics(
  serverData: TopicsCollectionData
): Array<Topic> {
  return serverData.data.map((entity: Topic) => entity);
}
export const sortTopicRowIds = (
  rowIds: string[],
  dataByRowId: { [key: string]: Topic }
): string[] => {
  return rowIds.sort((a, b) => {
    const topicA = dataByRowId[a] as Topic;
    const topicB = dataByRowId[b] as Topic;

    const nameA = topicA?.name?.toLowerCase() || '';
    const nameB = topicB?.name?.toLowerCase() || '';

    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
};

export const stringId = ({ id }: Pick<Topic, 'id'>): string => `${id}`;

export const useTopicsByIdsQuery = (
  programId: number,
  topicIds: string[]
): Array<TopicQuery> => {
  const queries =
    topicIds.map((id) => {
      return {
        queryKey: `${programId}-${id}`,
        queryFn: () => fetchTopicById(programId, id),
        options: { retry: false },
      };
    }) || [];

  const topics = useQueries(queries) as Array<UseQueryResult<Topic>>;
  const result: Array<TopicQuery> = [];

  topics.forEach((topicData) => {
    const { data, isLoading } = topicData;
    let topic: Topic | undefined;

    if (data) topic = data;

    result.push({ data: topic, isLoading });
  });

  return result;
};

export const useTopicsQuery = (
  props: FetchParams & { enabled?: boolean }
): QueryResponse<Array<Topic>> => {
  const { isLoading, error, data } = useQuery<TopicsCollectionData, Error>(
    ['topics', { ...props }],
    () => fetchTopics(props),
    {
      retry: false,
      select: (d) => ({
        data: mapServerDataToTopics(d),
        meta: d.meta,
      }),
      enabled: props.enabled,
    }
  );
  return {
    isLoading,
    errorMessage: error?.message,
    data: data && data.data,
  };
};

export const useTopicsInfiniteQuery = (
  props: Omit<FetchParams, 'page'>
): InfiniteQueryResponse<Topic> => {
  const {
    programId,
    search,
    status = 'available',
    autoFollow,
    autoPublish,
    includeDefault,
    userSubmittable,
    topicTags,
    visibility,
    pageSize = 20,
  } = props;

  const {
    data,
    error,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery<TopicsCollectionData, Error>(
    ['topics-infinite', JSON.stringify(props)],
    async ({ pageParam }) =>
      fetchTopics({
        programId,
        search,
        pageSize,
        status,
        autoFollow,
        autoPublish,
        visibility,
        userSubmittable,
        topicTags,
        page: pageParam as number,
        includeDefault,
      }),
    {
      cacheTime: 0,
      getNextPageParam: (lastGroup) =>
        lastGroup && nextPageToFetch(lastGroup.meta, pageSize),
    }
  );

  const flatData =
    data?.pages
      ?.map((batch) => (batch ? mapServerDataToTopics(batch) : []))
      .flat(1) || [];

  return {
    isLoading: isFetching,
    errorMessage: error?.message,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    meta: data?.pages[0].meta,
    data: flatData || [],
  };
};

export const useTopicQuery = (
  programId: number,
  id: string
): QueryResponse<Topic> => {
  const { isLoading, error, data } = useQuery<Topic, Error>({
    queryKey: `${programId}-${id}`,
    queryFn: () => fetchTopicById(programId, id),
    cacheTime: 0,
    enabled: Number(id) !== -1 && Number.isInteger(Number(id)),
  });

  return {
    isLoading,
    errorMessage: error?.message,
    data,
  };
};

export const useEditTopicQuery = (
  programId: number,
  id: number
): QueryResponse<Topic> => {
  const { isLoading, error, data } = useQuery<Topic, Error>({
    queryKey: `${programId}-${id}-edit`,
    queryFn: () => editTopic(programId, id),
    cacheTime: 0,
    enabled: Number(id) !== -1 && Number.isInteger(Number(id)),
  });

  return {
    isLoading,
    error,
    errorMessage: error?.message,
    data,
  };
};

/**
 * Strip out unnecessary fields from the topic before sending it to the server
 * @param data
 */
const cleanUp = <T extends Partial<Topic>>(data: T) => ({
  ...data,
  landingPageTabs: data.landingPageTabs?.map((tab) => {
    if (tab.tabType !== LandingPageTabType.Posts) return tab;

    return {
      ...tab,
      pinnedContents: tab.pinnedContents.map((content) => ({
        id: content.id,
      })),
    };
  }),
});

export const useInitializeTopic = (
  programId: number,
  { onSuccess, onError, onMutate }: MutationOptions<Topic, Error> = {}
): {
  initializeTopic: () => void;
  isLoading: boolean;
} => {
  const initialize = () => {
    return initializeTopic(programId);
  };

  const mutation = useMutation<Topic, Error>(initialize, {
    onMutate,
    onSuccess: (data) => {
      if (onSuccess) onSuccess(data);
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });

  return { initializeTopic: mutation.mutate, isLoading: mutation.isLoading };
};

export const useUpdateTopic = (
  programId: number,
  { onSuccess, onError, onMutate }: MutationOptions<string, Error> = {}
): {
  update: (topic: Topic) => void;
  updateAsync: (topic: Topic) => Promise<Topic>;
  isLoading: boolean;
} => {
  const update = (topic: Topic) => {
    return updateTopic(programId, cleanUp(topic));
  };
  const queryClient = useQueryClient();

  const mutation = useMutation<Topic, Error, Topic>(update, {
    onMutate,
    onSuccess: (topic) => {
      // Refresh useTopicQuery
      queryClient.setQueryData(`${programId}-${topic.id}`, topic);
      if (onSuccess) onSuccess('');
    },
    onError: (error) => {
      if (onError) onError(error);
    },
    useErrorBoundary: false,
  });

  return {
    update: mutation.mutate,
    updateAsync: mutation.mutateAsync,
    isLoading: mutation.isLoading,
  };
};

export const useArchiveTopic = (
  programId: number,
  { onSuccess, onError }: MutationOptions<Topic> = {}
): {
  archive: (topicId: number) => void;
} => {
  const queryClient = useQueryClient();
  const archive = (topicId: number) => {
    archiveTopic(programId, topicId)
      .then((data) => {
        queryClient.invalidateQueries(`${programId}-${topicId}`);
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { archive };
};

export const useUnarchiveTopic = (
  programId: number,
  { onSuccess, onError }: MutationOptions<Topic> = {}
): {
  unarchive: (topicId: number) => void;
} => {
  const queryClient = useQueryClient();
  const unarchive = (topicId: number) => {
    unarchiveTopic(programId, topicId)
      .then((data) => {
        queryClient.invalidateQueries(`${programId}-${topicId}`);
        if (onSuccess) onSuccess(data);
      })
      .catch((err) => {
        if (onError) onError(err.message);
      });
  };
  return { unarchive };
};

export const useTopContributorsQuery = (
  channelId: number
): QueryResponse<Array<User>> => {
  const programId = useProgram().id;
  const { isLoading, error, data } = useQuery({
    queryKey: ['top-contributors', programId, channelId],
    queryFn: () => fetchTopContributors(programId, channelId),
    select: (d) => d.data,
  });

  return {
    isLoading,
    errorMessage:
      error instanceof Error
        ? error.message
        : 'Something went wrong fetching top contributors',
    data: data ?? [],
  };
};

export const useCreateTopic = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string, Error> = {}
): {
  create: (topic: Partial<Topic>) => void;
  isLoading: boolean;
} => {
  const create = (topic: Partial<Topic>) => {
    return createTopic(programId, cleanUp(topic));
  };

  const mutation = useMutation<Partial<Topic>, Error, Partial<Topic>>(create, {
    onSuccess: () => {
      if (onSuccess) onSuccess('');
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });

  return { create: mutation.mutate, isLoading: mutation.isLoading };
};

export const useBulkArchiveTopics = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string, Error> = {}
): {
  bulkArchive: (
    bulkSelection: BulkSelection,
    filterConfig: TopicBulkActionFilters
  ) => void;
} => {
  const bulkArchive = (
    bulkSelection: BulkSelection,
    filterConfig: TopicBulkActionFilters
  ) => {
    bulkArchiveTopics(programId, bulkSelection, filterConfig)
      .then(() => {
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { bulkArchive };
};

export const useBulkUnarchiveTopics = (
  programId: number,
  { onSuccess, onError }: MutationOptions<string, Error> = {}
): {
  bulkUnarchive: (
    bulkSelection: BulkSelection,
    filterConfig: TopicBulkActionFilters
  ) => void;
} => {
  const bulkUnarchive = (
    bulkSelection: BulkSelection,
    filterConfig: TopicBulkActionFilters
  ) => {
    bulkUnarchiveTopics(programId, bulkSelection, filterConfig)
      .then(() => {
        if (onSuccess) onSuccess('');
      })
      .catch((err) => {
        if (onError) onError(err);
      });
  };
  return { bulkUnarchive };
};

export const useTopicFollowersQuery = (
  programId: number,
  ids: (string | number)[],
  retry: boolean | number = false
): QueryResponse<FetchTopicFollowersCountResponse> => {
  const { isLoading, error, data } = useQuery<
    FetchTopicFollowersCountResponse,
    Error
  >(
    ['audienceUsers', programId, ids],
    () => fetchTopicFollowersCount(programId, ids),
    { retry }
  );
  return { isLoading, errorMessage: error?.message, data };
};

export const getTopicAboutPageQueryKey = (
  programId: number,
  topicId: Topic['id']
): string => `${programId}-${topicId}-about`;

export const useTopicAboutPageQuery = (
  programId: number,
  id: Topic['id']
): QueryResponse<AboutPage> & { isEmpty: boolean } => {
  const { isLoading, error, data } = useQuery<AboutPage, Error>({
    queryKey: getTopicAboutPageQueryKey(programId, id),
    queryFn: () => fetchTopicAboutPage(programId, id),
    enabled: Number(id) !== -1 && Number.isInteger(Number(id)),
  });

  return {
    isLoading,
    errorMessage: error?.message,
    data,
    isEmpty:
      typeof data?.pageContent !== 'string' ||
      data.pageContent.length < 1 ||
      data.pageContent === '<p></p>',
  };
};

type AboutPageInput = {
  pageContent?: string;
  previewContent?: string;
  createDesign?: boolean;
  fontIds?: number[];
};

export const useUpdateTopicAboutPage = (
  programId: number,
  id: Topic['id'],
  { onSuccess, onError }: MutationOptions<AboutPage, Error> = {}
): {
  update: (aboutPageInput: AboutPageInput) => void;
  isLoading: boolean;
} => {
  const updateTopicAboutPageFn = ({
    pageContent,
    previewContent,
    fontIds,
    createDesign,
  }: AboutPageInput) => {
    return updateTopicAboutPage(
      programId,
      id,
      pageContent,
      previewContent,
      fontIds,
      createDesign
    );
  };

  const queryClient = useQueryClient();

  const mutation = useMutation<AboutPage, Error, AboutPageInput>(
    updateTopicAboutPageFn,
    {
      onSuccess: (data) => {
        queryClient.setQueryData<AboutPage>(
          getTopicAboutPageQueryKey(programId, id),
          () => {
            return data;
          }
        );

        if (onSuccess) {
          onSuccess(data);
        }
      },
      onError: (error) => {
        if (onError) onError(error);
      },
    }
  );

  return { update: mutation.mutate, isLoading: mutation.isLoading };
};

export const useTopicShortcutsQuery = (
  programId: number,
  topicId?: Topic['id']
): QueryResponse<TopicShortcut[]> => {
  return useQuery({
    queryKey: ['topicShortcuts', programId, topicId],
    queryFn: () =>
      topicId !== undefined ? fetchTopicShortcuts(programId, topicId) : [],
    enabled:
      topicId !== undefined &&
      Number(topicId) !== -1 &&
      Number.isInteger(Number(topicId)),
  });
};

export const useCreateTopicShortcut = (
  programId: number,
  topicId?: Topic['id'],
  { onSuccess, onError }: MutationOptions<TopicShortcut, Error> = {}
): {
  create: (shortcut: Omit<TopicShortcut, 'id'>) => Promise<TopicShortcut>;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();

  const create = (shortcut: Omit<TopicShortcut, 'id'>) => {
    if (!topicId) {
      throw new Error('Topic ID is required');
    }
    return createTopicShortcut(programId, topicId, shortcut);
  };

  const mutation = useMutation(create, {
    onSuccess: (data) => {
      queryClient.setQueryData<TopicShortcut[]>(
        ['topicShortcuts', programId, topicId],
        (old) => (old ? [...old, data] : [data])
      );
      if (onSuccess) onSuccess(data);
    },
    onError: (error: Error) => {
      if (onError) onError(error);
    },
  });

  return { create: mutation.mutateAsync, isLoading: mutation.isLoading };
};

export const useUpdateTopicShortcut = (
  programId: number,
  topicId?: Topic['id'],
  { onSuccess, onError }: MutationOptions<TopicShortcut, Error> = {}
): {
  update: (shortcut: TopicShortcut) => Promise<TopicShortcut>;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();

  const update = (shortcut: TopicShortcut) => {
    if (!topicId) {
      throw new Error('Topic ID is required');
    }
    return updateTopicShortcut(programId, topicId, shortcut);
  };

  const mutation = useMutation(update, {
    onSuccess: (data) => {
      queryClient.setQueryData<TopicShortcut[]>(
        ['topicShortcuts', programId, topicId],
        (old) => (old ? old.map((s) => (s.id === data.id ? data : s)) : [data])
      );
      if (onSuccess) onSuccess(data);
    },
    onError: (error: Error) => {
      if (onError) onError(error);
    },
  });

  return { update: mutation.mutateAsync, isLoading: mutation.isLoading };
};

export const useDeleteTopicShortcut = (
  programId: number,
  topicId?: Topic['id'],
  { onSuccess, onError }: MutationOptions<void, Error> = {}
): {
  destroy: (shortcut: TopicShortcut) => Promise<void>;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();

  const deleteShortcut = (shortcut: TopicShortcut) => {
    if (!topicId) {
      throw new Error('Topic ID is required');
    }
    return deleteTopicShortcut(programId, topicId, shortcut.id);
  };

  const mutation = useMutation(deleteShortcut, {
    onSuccess: (data, deleted) => {
      queryClient.setQueryData<TopicShortcut[]>(
        ['topicShortcuts', programId, topicId],
        (old) => (old ? old.filter((s) => s.id !== deleted.id) : [])
      );
      if (onSuccess) onSuccess();
    },
    onError: (error: Error) => {
      if (onError) onError(error);
    },
  });

  return { destroy: mutation.mutateAsync, isLoading: mutation.isLoading };
};

export const useReorderTopicShortcuts = (
  programId: number,
  topicId?: Topic['id'],
  { onSuccess, onError }: MutationOptions<void, Error> = {}
): {
  reorder: (shortcuts: TopicShortcut[]) => Promise<void>;
  isLoading: boolean;
} => {
  const queryClient = useQueryClient();

  const reorder = (shortcuts: TopicShortcut[]) => {
    if (!topicId) {
      throw new Error('Topic ID is required');
    }
    return reorderTopicShortcuts(programId, topicId, shortcuts);
  };

  const mutation = useMutation(reorder, {
    onSuccess: (data, shortcuts) => {
      queryClient.setQueryData(
        ['topicShortcuts', programId, topicId],
        shortcuts
      );
      if (onSuccess) onSuccess();
    },
    onError: (error: Error) => {
      if (onError) onError(error);
    },
  });

  return { reorder: mutation.mutateAsync, isLoading: mutation.isLoading };
};

export const usePublishTopic = (
  programId: number,
  { onSuccess, onError, onMutate }: MutationOptions<Topic, Error> = {}
): {
  publish: (topicId: number) => void;
  isLoading: boolean;
} => {
  const publish = (topicId: number) => {
    return publishTopic(programId, topicId);
  };
  const mutation = useMutation<Topic, Error, number>(publish, {
    onMutate,
    onSuccess: (data) => {
      if (onSuccess) onSuccess(data);
    },
    onError: (error) => {
      if (onError) onError(error);
    },
  });

  return {
    publish: mutation.mutate,
    isLoading: mutation.isLoading,
  };
};

export const useDeleteTopicDraft = (
  programId: number,
  { onSuccess, onError, onMutate }: MutationOptions<void, Error> = {}
): { deleteDraft: (topic: Topic) => void; isLoading: boolean } => {
  const deleteDraftImpl = async (topic: Topic) => {
    if (topic.draftId) await deleteDraft(programId, topic.draftId);
    if (topic.draft) await deleteDraft(programId, Number(topic.id));
  };

  const mutation = useMutation<void, Error, Topic>(deleteDraftImpl, {
    onMutate,
    onError: (error) => {
      if (onError) onError(error);
    },
    onSuccess: () => {
      if (onSuccess) onSuccess();
    },
  });

  return {
    deleteDraft: mutation.mutate,
    isLoading: mutation.isLoading,
  };
};
