import qs from 'qs';
import { CaptionLocaleUrl, Video } from 'models/video';
import snakecaseKeys from 'snakecase-keys';
import { request } from './api-shared';
import { fqApiUrl } from './common';
import { headers, parseResponse } from './helpers/json-api';
import { UploadProgress, uploadToS3 } from './helpers/upload-to-s3';

export type FetchedCaption = CaptionLocaleUrl & {
  text: string;
};

export type TranscribeProps = {
  programId: number;
  isDesignAsset?: boolean;
  videoId?: number;
};

export type JobDismissProps = {
  programId: number;
  jobId: number;
};

export type TranslationLanguagesProps = {
  programId: number;
};

export type TranslateProps = {
  programId: number;
  videoId?: number;
  languages: string[];
  isDesignAsset?: boolean;
  sourceCaptionId?: number;
};

export type TranscriptionJob = {
  id: number;
  status: 'processing' | 'completed' | 'failed';
  caption?: FetchedCaption;
  message: string | null;
};

export type TranslationJob = {
  id: number;
  status: 'waiting' | 'processing' | 'completed' | 'failed';
  captions?: FetchedCaption[];
  locales: string[];
};

export async function transcribeVideo({
  programId,
  isDesignAsset,
  videoId,
}: TranscribeProps): Promise<TranscriptionJob> {
  const requestUrl = isDesignAsset
    ? `v2/programs/${programId}/design_videos/${videoId}/transcribe`
    : `samba/programs/${programId}/videos/${videoId}/transcribe`;

  const response = await request(fqApiUrl(requestUrl), {
    method: 'POST',
    headers,
  });

  const result = await parseResponse<TranscriptionJob>(response);
  return {
    ...result,
    caption: result.caption && (await resolveCaptionText(result.caption)),
  };
}

export async function dismissTranscriptionJob({
  programId,
  jobId,
}: JobDismissProps): Promise<TranscriptionJob> {
  const requestUrl = `samba/programs/${programId}/videos/transcribe/${jobId}/dismiss`;

  const response = await request(fqApiUrl(requestUrl), {
    method: 'POST',
    headers,
  });
  const result = await parseResponse<TranscriptionJob>(response);
  return result;
}

export async function translateVideo({
  programId,
  isDesignAsset,
  videoId,
  languages,
  sourceCaptionId,
}: TranslateProps): Promise<TranslationJob> {
  const requestUrl = isDesignAsset
    ? `v2/programs/${programId}/design_videos/${videoId}/translate`
    : `samba/programs/${programId}/videos/${videoId}/translate`;

  const response = await request(fqApiUrl(requestUrl), {
    method: 'POST',
    headers,
    body: JSON.stringify(
      snakecaseKeys({
        locales: languages,
        sourceCaptionId,
      })
    ),
  });

  const result = await parseResponse<TranslationJob>(response);
  return result;
}

export async function dismissTranslationJob({
  programId,
  jobId,
}: JobDismissProps): Promise<TranslationJob> {
  const requestUrl = `samba/programs/${programId}/videos/translate/${jobId}/dismiss`;

  const response = await request(fqApiUrl(requestUrl), {
    method: 'POST',
    headers,
  });
  const result = await parseResponse<TranslationJob>(response);
  return result;
}

export type CaptionsJobs = {
  transcription?: TranscriptionJob;
  translation?: TranslationJob;
};

type Nullable<T> = { [K in keyof T]: T[K] | null };
type ServerCaptionsJob = Nullable<CaptionsJobs>;

export async function fetchCaptionsJobs({
  programId,
  isDesignAsset,
  videoId,
}: TranscribeProps): Promise<CaptionsJobs> {
  const requestUrl = isDesignAsset
    ? `v2/programs/${programId}/design_videos/${videoId}/captions_jobs`
    : `samba/programs/${programId}/videos/${videoId}/captions_jobs`;

  const response = await request(fqApiUrl(requestUrl));

  const result = await parseResponse<ServerCaptionsJob>(response);

  return {
    ...result,
    transcription: result.transcription
      ? {
          ...result.transcription,
          caption:
            result.transcription.caption &&
            (await resolveCaptionText(result.transcription.caption)),
        }
      : undefined,
    translation: result.translation
      ? {
          ...result.translation,
          captions:
            result.translation.captions &&
            (await Promise.all(
              result.translation.captions.map(resolveCaptionText)
            )),
        }
      : undefined,
  };
}

const resolveCaptionText = async (
  caption: Omit<FetchedCaption, 'text'>
): Promise<FetchedCaption> => {
  const response = await fetch(caption.url, { credentials: 'include' });
  return {
    ...caption,
    text: response.ok ? await response.text() : '',
  };
};

export async function fetchCaptionsUploadUrl(
  programId: number,
  filename: string
): Promise<string> {
  const query = qs.stringify({ filename });
  const response = await request(
    fqApiUrl(`samba/programs/${programId}/videos/caption_upload_url?${query}`)
  );

  const result = await parseResponse<{ url: string }>(response);
  return result.url;
}

type BaseCaptionOptions = {
  programId: number;
  isDesignAsset?: boolean;
  videoId: number;
};

export type UploadCaptionOptions = BaseCaptionOptions & {
  file: File;
  onUploadProgress?: (progress: UploadProgress) => void;
};

export type RemoveCaptionsOptions = BaseCaptionOptions & {
  url?: string;
};

export type UpdateCaptionsOptions = BaseCaptionOptions & {
  toAdd?: File | File[];
  onUploadProgress?: (progress: UploadProgress) => void;
  toRemove?: string | 'all';
};

type UpdateCaptionsBodyParams = {
  captions?: Record<'url', string>[];
  remove_caption_by_url?: string;
  remove_all_captions?: boolean;
};

export type UpdateCaptionsResponse = {
  video: Video;
  errors?: string[];
};

export type UploadCaptionsResponse = UpdateCaptionsResponse;
export type RemoveCaptionsResponse = UpdateCaptionsResponse;

export async function uploadCaptions({
  file,
  programId,
  isDesignAsset,
  videoId,
  onUploadProgress,
}: UploadCaptionOptions): Promise<UploadCaptionsResponse> {
  return updateCaptions({
    programId,
    isDesignAsset,
    videoId,
    toAdd: file,
    onUploadProgress,
  });
}

export async function removeCaptions({
  programId,
  isDesignAsset,
  videoId,
  url,
}: RemoveCaptionsOptions): Promise<RemoveCaptionsResponse> {
  return updateCaptions({
    programId,
    isDesignAsset,
    videoId,
    toRemove: url || 'all',
  });
}

// This combines the uploadCaptions and removeCaptions to a single function
export async function updateCaptions({
  programId,
  isDesignAsset,
  videoId,
  toAdd,
  onUploadProgress,
  toRemove,
}: UpdateCaptionsOptions): Promise<UpdateCaptionsResponse> {
  const requestBody: UpdateCaptionsBodyParams = {};

  const responseCaptions: Array<{ url: string }> = [];
  const updateErrors: string[] = [];

  const captionsToUpload = Array.isArray(toAdd) ? toAdd : [toAdd];

  const captionUploadUrlsResponse = await Promise.allSettled(
    captionsToUpload
      .filter((fileToAdd) => !!fileToAdd)
      .map((fileToAdd) => (fileToAdd as File).name.replace(/[^\w-_.]/g, ''))
      .map((filename) => fetchCaptionsUploadUrl(programId, filename))
  );

  const captionUploadUrls: Array<{ file: File; url: string }> = [];

  captionUploadUrlsResponse.forEach((response, i) => {
    if (response.status === 'fulfilled') {
      captionUploadUrls.push({
        file: captionsToUpload[i] as File,
        url: response.value,
      });
    } else {
      updateErrors.push(`Error uploading caption ${captionsToUpload[i]?.name}`);
    }
  });

  const uploadResponses = await Promise.allSettled(
    captionUploadUrls.map(({ file, url }) =>
      uploadToS3({ url, file, onUploadProgress })
    )
  );

  uploadResponses.forEach((response, i) => {
    if (response.status === 'fulfilled') {
      responseCaptions.push({ url: captionUploadUrls[i].url });
    } else {
      updateErrors.push(
        `Error uploading caption ${captionUploadUrls[i].file.name}`
      );
    }
  });

  if (responseCaptions.length) {
    requestBody.captions = responseCaptions;
  }

  if (toRemove) {
    if (toRemove === 'all') {
      requestBody.remove_all_captions = true;
    } else {
      requestBody.remove_caption_by_url = toRemove;
    }
  }

  const requestUrl = isDesignAsset
    ? `v2/programs/${programId}/design_videos/${videoId}`
    : `samba/programs/${programId}/videos/${videoId}`;

  const response = await request(fqApiUrl(requestUrl), {
    method: 'PUT',
    body: JSON.stringify(requestBody),
    headers,
  });

  return {
    video: await parseResponse<Video>(response),
    errors: updateErrors,
  };
}

export type TranslationLanguage = {
  languageCode: string;
  languageName: string;
};

export async function fetchTranslationLanguages({
  programId,
}: TranslationLanguagesProps): Promise<Array<TranslationLanguage>> {
  const requestUrl = `samba/programs/${programId}/videos/translate/languages`;

  const response = await request(fqApiUrl(requestUrl));

  const result = await parseResponse<Array<TranslationLanguage>>(response);
  return result;
}
