import React from 'react';
import { useDelayedAction } from './useDelayedAction';

type UseStateSyncer = <T extends unknown>(
  key: string,
  onSync?: (value: T) => void,
  delay?: number
) => {
  sync: (value: T) => void;
};

// This hook leverages the localStorage storage event to transfer data
// to other browser tabs on the same domain.  Data is set in the storage
// and immediately removed, which triggers the event.  The receiving
// browsing context will receive the new data from the event.
export const useStateSyncer: UseStateSyncer = (
  key,
  onSync,
  delay = 10 * 1000
) => {
  const dataRef = React.useRef<unknown>();

  React.useEffect(() => {
    const handler = (event: StorageEvent) => {
      if (event.key !== key || !event.newValue || !onSync) return;

      try {
        onSync(JSON.parse(event.newValue));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Unable to parse payload.', error);
      }
    };

    window.addEventListener('storage', handler);
    return () => window.removeEventListener('storage', handler);
  }, [key, onSync]);

  const { cancel, schedule } = useDelayedAction(() => {
    if (!('localStorage' in window)) return;

    try {
      window.localStorage.setItem(key, JSON.stringify(dataRef.current));
      window.localStorage.removeItem(key);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Unable to sync payload.', error);
    }
  }, delay);

  const sync = (data: unknown) => {
    dataRef.current = data;
    cancel();
    schedule();
  };

  return { sync };
};
