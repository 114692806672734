import React from 'react';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { Flex, FlexItem } from 'DesignSystem/Layout/Flex';
import * as Text from 'DesignSystem/Typography';
import { Box } from 'DesignSystem/Components';
import { TranscriptionJob, TranslationJob } from 'services/api-captions';
import { languageDisplayName } from 'utility/language';
import { Alert } from '@socialchorus/shared-ui-components';

type JobStatusProps<T> = {
  captionJob: T;
  waitingLabel?: string;
  processingLabel: string;
  failureLabel: string;
  onDismiss?: (jobId: number) => void;
};

type TranscriptionJobStatusProps = JobStatusProps<TranscriptionJob>;
type TranslationJobStatusProps = JobStatusProps<TranslationJob>;

type CaptionJobStatusProps =
  | TranscriptionJobStatusProps
  | TranslationJobStatusProps;

const FAILURE_DESCRIPTIONS: Readonly<Record<string, string>> = Object.freeze({
  'Empty Transcript': "Transcription didn't detect any words in your video.",
});

export const CaptionJobStatus: React.FC<CaptionJobStatusProps> = ({
  captionJob,
  waitingLabel,
  processingLabel,
  failureLabel,
  onDismiss,
}) => {
  return (
    <>
      {captionJob &&
        captionJob.status === 'waiting' &&
        captionJob.locales.map((languageCode) => (
          <Box
            radius={4}
            margin={[8, 0, 0, 0]}
            padding={[12, 16]}
            color={Text.background.gray05}
            key={languageCode}
          >
            <Flex start>
              <FlexItem widen start>
                <Text.Body bold>{languageDisplayName(languageCode)}</Text.Body>
              </FlexItem>
              <LoadingSpinner size="xsmall" />
              <Box padding={[0, 0, 0, 8]}>
                <Text.Caption color={Text.color.gray90}>
                  {waitingLabel}
                </Text.Caption>
              </Box>
            </Flex>
          </Box>
        ))}
      {captionJob && captionJob.status === 'processing' && (
        <Box
          radius={4}
          margin={[8, 0, 0, 0]}
          padding={[12, 16]}
          color={Text.background.gray05}
        >
          <Flex start>
            <LoadingSpinner size="xsmall" />
            <Box padding={[0, 0, 0, 8]}>
              <Text.Caption color={Text.color.gray90}>
                {processingLabel}
              </Text.Caption>
            </Box>
          </Flex>
        </Box>
      )}
      {captionJob && captionJob.status === 'failed' && (
        <Alert
          type="warning"
          size="compact"
          iconName="warning"
          onClose={() => {
            if (onDismiss) {
              onDismiss(captionJob.id);
            }
          }}
        >
          <Text.Caption semibold color={Text.color.gray90}>
            {failureLabel}
          </Text.Caption>
          {FAILURE_DESCRIPTIONS[failureLabel] && (
            <Text.Caption color={Text.color.gray70}>
              {FAILURE_DESCRIPTIONS[failureLabel]}
            </Text.Caption>
          )}
        </Alert>
      )}
    </>
  );
};
