import React from 'react';
import { useFlashMessage } from 'contexts/flasher';
import { Box, FormModal } from 'DesignSystem/Components';
import { Button, FieldInput, Radio } from 'DesignSystem/Form';
import * as Text from 'DesignSystem/Typography';
import { Flex, FlexItem } from 'DesignSystem/Layout/Flex';
import { VideoFieldData } from 'models/donkey';
import { FetchedCaption } from 'services/api-captions';
import { LoadingSpinner } from 'shared/LoadingSpinner';
import { languageDisplayName } from 'utility/language';
import { Trash } from 'shared/icons';
import { Alert, Badge } from '@socialchorus/shared-ui-components';
import {
  CaptionsSubmittedSuccessProps,
  CaptionsType,
  UpdateCaptions,
  useCaptionSettings,
} from '../../hooks/useCaptionSettings';
import { UploadedCaptionsForm } from './UploadedCaptionsForm';
import { useCaptionsJobs } from '../../hooks/useCaptionsJobs';
import { CaptionsTranslation } from './CaptionsTranslation';
import { CaptionDeletionModal } from './CaptionDeletionModal';
import { CaptionsVideoPreview } from './CaptionsVideoPreview';
import styles from '../video.module.css';
import { CaptionValidationMessages } from './CaptionValidationMessages';

export type CaptionSettingsModalProps = {
  onClose: () => void;
  updateCaptions: UpdateCaptions;
  isCaptionsUpdating: boolean;
  currentCaption?: FetchedCaption;
  fieldData: VideoFieldData;
  isDesignAsset: boolean;
};

export const CaptionSettings: React.FC<CaptionSettingsModalProps> = ({
  onClose,
  updateCaptions,
  isCaptionsUpdating,
  currentCaption,
  fieldData,
  isDesignAsset,
}) => {
  enum FormState {
    Main,
    Validation,
  }

  /* hooks */

  const {
    onFormSubmit: submitForm,
    captionText,
    onCaptionTextChange,
    onReplaceClick,
    onRemoveClick,
    onUploadClick,
    fileInput,
    errorMessage,
    isDisabled,
    validationErrors,
    isTranscribing,
    isTranslating,
    captionsType,
    setCaptionsType,
    autoTranslateCaptions,
    setAutoTranslateCaptions,
    selectedLanguages,
    setSelectedLanguages,
    selectedCaptionFiles,
    onRemoveCaptionClick,
  } = useCaptionSettings({
    updateCaptions,
    isCaptionsUpdating,
    currentCaption,
    videoId: fieldData?.video_id,
    isDesignAsset,
  });

  const {
    isCaptionTranscriptionEnabled,
    isCaptionTranslationEnabled,
    isLoadingFeatureFlags,
  } = useCaptionsJobs();

  const isMultiFileUploadEnabled = isCaptionTranscriptionEnabled;

  const { setFlashMessage } = useFlashMessage();

  /* states */

  const [isDeleting, setIsDeleting] = React.useState<boolean>(false);

  const [formState, setFormState] = React.useState<FormState>(FormState.Main);

  const isFormDisabled = React.useMemo(() => {
    if (
      captionsType === CaptionsType.GENERATE &&
      formState === FormState.Main &&
      (!autoTranslateCaptions || selectedLanguages.length > 0)
    ) {
      return false;
    }

    if (formState === FormState.Validation) {
      if (!validationErrors) {
        return false;
      }

      return !Object.keys(validationErrors).some(
        (filename) => validationErrors[filename].length === 0
      );
    }

    return isDisabled;
  }, [
    FormState.Main,
    FormState.Validation,
    autoTranslateCaptions,
    captionsType,
    formState,
    isDisabled,
    selectedLanguages.length,
    validationErrors,
  ]);

  /* callbacks */

  const toggleAutoTranslateCaptions = React.useCallback(() => {
    if (autoTranslateCaptions) {
      setSelectedLanguages([]);
    }

    setAutoTranslateCaptions(!autoTranslateCaptions);
  }, [autoTranslateCaptions, setAutoTranslateCaptions, setSelectedLanguages]);

  const showFlashMessage = React.useCallback(
    (props: CaptionsSubmittedSuccessProps) => {
      if (props.transcribed) {
        setFlashMessage({
          severity: 'progress',
          message: 'Your captions are being processed.',
        });
      } else if (props.uploaded) {
        if (props.edited) {
          setFlashMessage({
            severity: 'info',
            message: 'Caption edits have been saved.',
          });
        } else {
          setFlashMessage({
            severity: 'progress',
            message: 'Your captions are being processed.',
          });
        }
      } else if (props.deleted) {
        setFlashMessage({
          severity: 'info',
          message: 'Your caption file has been deleted.',
        });
      }
    },
    [setFlashMessage]
  );

  const onSubmit = React.useCallback(() => {
    if (
      isMultiFileUploadEnabled &&
      formState === FormState.Main &&
      captionsType === CaptionsType.UPLOAD
    ) {
      setFormState(FormState.Validation);
    } else {
      submitForm({
        onSuccess: (props: CaptionsSubmittedSuccessProps) => {
          if (isCaptionTranscriptionEnabled) {
            showFlashMessage(props);
          }
          onClose();
        },
      });
    }
  }, [
    FormState.Main,
    FormState.Validation,
    captionsType,
    formState,
    isCaptionTranscriptionEnabled,
    isMultiFileUploadEnabled,
    onClose,
    showFlashMessage,
    submitForm,
  ]);

  /* handlers */

  const selectUpload = () => {
    setCaptionsType(CaptionsType.UPLOAD);
    setAutoTranslateCaptions(false);
    setSelectedLanguages([]);
  };

  const selectGenerate = () => setCaptionsType(CaptionsType.GENERATE);

  const modalTitle = () => {
    if (formState === FormState.Main) {
      return (
        (isCaptionTranscriptionEnabled &&
          (captionText ? 'Edit Captions' : 'Add Captions')) ||
        'Caption Settings'
      );
    }

    if (formState === FormState.Validation) {
      return 'File Validation';
    }

    return '';
  };

  const modalDescription = () => {
    if (formState === FormState.Main) {
      return isCaptionTranscriptionEnabled && !captionText
        ? 'Captions can be edited, translated, and downloaded after processing.'
        : undefined;
    }

    if (formState === FormState.Validation) {
      return 'Files with a success status will be processed upon clicking Continue.';
    }

    return '';
  };

  const invalidCaptionFiles = validationErrors
    ? Object.keys(validationErrors).filter(
        (filename) => validationErrors[filename].length > 0
      )
    : [];

  return isLoadingFeatureFlags ? null : (
    <FormModal
      modalBodyClassName={
        isCaptionTranscriptionEnabled ? styles.modalBodyWrapper : undefined
      }
      entityText={modalTitle()}
      actionText=""
      description={modalDescription()}
      submitButton={
        <CaptionSubmitButton
          onSubmit={onSubmit}
          submitLabel={
            isCaptionTranscriptionEnabled && captionText ? 'Save' : 'Continue'
          }
          isDisabled={isFormDisabled}
          isLoading={isCaptionsUpdating || isTranscribing || isTranslating}
        />
      }
      onCancel={onClose}
    >
      <Box
        className={
          isCaptionTranscriptionEnabled
            ? styles.addCaptions
            : styles.captionsSettings
        }
      >
        {isCaptionTranscriptionEnabled && formState === FormState.Main && (
          <Flex column className={styles.captionsFormWrapper}>
            {currentCaption ? (
              <>
                <div className={styles.videoPreviewWrapper}>
                  <CaptionsVideoPreview fieldData={fieldData} />
                </div>
                {isDeleting && (
                  <CaptionDeletionModal
                    onCancel={() => setIsDeleting(false)}
                    onDelete={() => {
                      updateCaptions({
                        toRemove: currentCaption.url,
                        onSuccess: () => {
                          setIsDeleting(false);
                          onClose();
                        },
                      });
                    }}
                    language={languageDisplayName(currentCaption.language)}
                    isLoading={isCaptionsUpdating}
                  />
                )}
              </>
            ) : (
              <>
                <Box style={{ display: 'flex' }}>
                  <Radio
                    type="radio"
                    label={
                      <Box margin={[0, 0, 0, -8]}>
                        <Text.Body>Automatically generate captions</Text.Body>
                      </Box>
                    }
                    checked={captionsType === CaptionsType.GENERATE}
                    onSelect={selectGenerate}
                  />
                </Box>
                {isCaptionTranslationEnabled &&
                  captionsType === CaptionsType.GENERATE && (
                    <>
                      <Box padding={[16, 0, 0, 32]} style={{ display: 'flex' }}>
                        <Radio
                          label={
                            <Box margin={[0, 0, 0, -8]}>
                              <Text.Body>
                                Auto-translate these captions
                              </Text.Body>
                            </Box>
                          }
                          checked={autoTranslateCaptions}
                          onChange={toggleAutoTranslateCaptions}
                        />
                      </Box>
                      {autoTranslateCaptions && (
                        <Box padding={[0, 0, 0, 32]}>
                          <CaptionsTranslation
                            disabled={!autoTranslateCaptions}
                            selectedLanguages={selectedLanguages}
                            setSelectedLanguages={setSelectedLanguages}
                          />
                        </Box>
                      )}
                    </>
                  )}

                <Box
                  padding={[16, 0]}
                  className={styles.uploadCaptionsWrapper}
                  style={{ display: 'flex' }}
                >
                  <Radio
                    type="radio"
                    label={
                      <Flex className={styles.uploadCaptionsLabel} column start>
                        <Text.Body>
                          Upload your own caption file
                          {isMultiFileUploadEnabled && '(s)'}
                        </Text.Body>
                        {isMultiFileUploadEnabled && (
                          <Text.Caption>
                            You can select multiple files at once for upload
                          </Text.Caption>
                        )}
                      </Flex>
                    }
                    checked={captionsType === CaptionsType.UPLOAD}
                    onSelect={selectUpload}
                  />
                </Box>
              </>
            )}
            {isCaptionTranscriptionEnabled && (
              <>
                <CaptionValidationMessages
                  validationErrors={Object.values(validationErrors || [])[0]}
                />
                {(captionsType === CaptionsType.UPLOAD || captionText) && (
                  <UploadedCaptionsForm
                    captionText={captionText}
                    onCaptionTextChange={onCaptionTextChange}
                    onReplaceClick={onReplaceClick}
                    onRemoveClick={() => setIsDeleting(true)}
                    onUploadClick={onUploadClick}
                    fileInput={fileInput}
                    errorMessage={errorMessage}
                    editorDisabled={isCaptionsUpdating}
                    dropzoneDisabled={captionsType === CaptionsType.GENERATE}
                    canRemove={!!currentCaption}
                  />
                )}
              </>
            )}

            {isCaptionTranscriptionEnabled &&
              isMultiFileUploadEnabled &&
              captionsType === CaptionsType.UPLOAD && (
                <>
                  {selectedCaptionFiles?.map((file) => (
                    <Box
                      radius={4}
                      relative
                      color={Text.background.gray05}
                      className={styles.captionsSelectedFile}
                    >
                      <Flex center>
                        <FlexItem widen start>
                          <Text.Body bold>{file.name}</Text.Body>
                        </FlexItem>
                        <Button
                          title="Remove Caption"
                          minimal
                          clearText
                          icon={
                            <Trash className={styles.removeCaptionButton} />
                          }
                          onClick={() => onRemoveCaptionClick(file)}
                          className={styles.iconButton}
                        />
                      </Flex>
                    </Box>
                  ))}
                </>
              )}
            {fileInput}
          </Flex>
        )}

        {isCaptionTranscriptionEnabled && formState === FormState.Validation && (
          <Box style={{ display: 'block' }}>
            {invalidCaptionFiles.length > 0 && (
              <Alert
                description={`The following files contain errors and won't be processed: ${invalidCaptionFiles.join(
                  ', '
                )}.`}
                size="default"
                type="danger"
                title=""
                iconName="warning"
              />
            )}

            {validationErrors &&
              Object.keys(validationErrors).map((filename) => (
                <Box className={styles.validationContainer}>
                  <Box>
                    <Text.Body semibold>File name:</Text.Body>
                    <Badge
                      text={filename}
                      className={styles.validationFilename}
                    />
                  </Box>
                  <Box>
                    {validationErrors[filename].length > 0 ? (
                      <table className={styles.validationSummary}>
                        <tr>
                          <th>
                            <Text.Body semibold>Status</Text.Body>
                          </th>
                          <th>
                            <Text.Body semibold>Row</Text.Body>
                          </th>
                          <th>
                            <Text.Body semibold>Result</Text.Body>
                          </th>
                        </tr>
                        {validationErrors[filename].map((error) => (
                          <tr>
                            <td>
                              <Badge
                                text="Error"
                                className={styles.validationNegative}
                              />
                            </td>
                            <td>
                              <Text.Body semibold>{error.lineNumber}</Text.Body>
                            </td>
                            <td>
                              <Text.Body>{error.message}</Text.Body>
                            </td>
                          </tr>
                        ))}
                      </table>
                    ) : (
                      <table className={styles.validationSummary}>
                        <tr>
                          <th>
                            <Text.Body semibold>Status</Text.Body>
                          </th>
                        </tr>
                        <tr>
                          <td>
                            <Badge
                              text="Success"
                              className={styles.validationPositive}
                            />
                          </td>
                        </tr>
                      </table>
                    )}
                  </Box>
                </Box>
              ))}
          </Box>
        )}

        {!isCaptionTranscriptionEnabled && formState === FormState.Main && (
          <FieldInput>
            <UploadedCaptionsForm
              captionText={captionText}
              validationErrors={validationErrors}
              onReplaceClick={onReplaceClick}
              onRemoveClick={onRemoveClick}
              onUploadClick={onUploadClick}
              fileInput={fileInput}
              errorMessage={errorMessage}
              canRemove={!!captionText}
            />
          </FieldInput>
        )}
      </Box>
    </FormModal>
  );
};

const CaptionSubmitButton: React.FC<{
  onSubmit: () => void;
  submitLabel: string;
  isDisabled: boolean;
  isLoading: boolean;
}> = ({ onSubmit, submitLabel, isDisabled, isLoading }) => {
  const label = isLoading ? (
    <>
      {submitLabel}&nbsp;
      <LoadingSpinner size="xsmall" />
    </>
  ) : (
    submitLabel
  );

  return (
    <Button
      disabled={isDisabled || isLoading}
      onClick={onSubmit}
      label={label}
    />
  );
};
