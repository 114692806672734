import React from 'react';
import * as Text from 'DesignSystem/Typography';
import { CaptionsEditor } from './CaptionsEditor';
import { CaptionsDropzone } from './CaptionsDropzone';
import { CaptionValidationError } from '../../utils/caption-format-validator';
import { CaptionValidationErrors } from './CaptionsValidationErrors';

type CaptionFormProps = {
  captionText?: string;
  validationErrors?: { [key: string]: CaptionValidationError[] };
  onReplaceClick: () => void;
  onRemoveClick: () => void;
  onUploadClick: () => void;
  onCaptionTextChange?: (text: string) => void;
  fileInput: React.ReactNode;
  errorMessage?: string;
  editorDisabled?: boolean;
  dropzoneDisabled?: boolean;
  canRemove?: boolean;
};

export const UploadedCaptionsForm: React.FC<CaptionFormProps> = ({
  captionText,
  validationErrors,
  onReplaceClick,
  onRemoveClick,
  onUploadClick,
  onCaptionTextChange,
  fileInput,
  errorMessage,
  editorDisabled,
  dropzoneDisabled,
  canRemove,
}) => {
  return (
    <>
      {captionText ? (
        <CaptionsEditor
          captionText={captionText}
          validationErrors={validationErrors}
          onReplaceClick={onReplaceClick}
          onRemoveClick={onRemoveClick}
          onCaptionTextChange={onCaptionTextChange}
          canRemove={canRemove}
          disabled={editorDisabled}
        />
      ) : (
        <CaptionsDropzone
          onUploadClick={onUploadClick}
          disabled={dropzoneDisabled}
        />
      )}
      <CaptionValidationErrors validationErrors={validationErrors} />
      {errorMessage && (
        <Text.Body color={Text.color.redFull}>{errorMessage}</Text.Body>
      )}
      {fileInput}
    </>
  );
};
