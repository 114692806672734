import React from 'react';
import { Button } from 'DesignSystem/Form';
import { Alert, AlertType } from 'DesignSystem/Components';
import { ExclamationTriangleOutlined } from 'shared/icons';
import styles from '../video.module.css';
import { CaptionValidationError } from '../../utils/caption-format-validator';

type CaptionsValidationErrorsComponentProps = {
  validationErrors?: { [key: string]: CaptionValidationError[] };
};

const scrollToError = (lineNumber: number) => {
  const line = document.getElementById(`codeline-id-${lineNumber}`);
  if (line) {
    line.scrollIntoView();
  }
};

export const CaptionValidationErrors: React.FC<CaptionsValidationErrorsComponentProps> = ({
  validationErrors,
}) => {
  const captionValidationErrors =
    validationErrors && Object.values(validationErrors)[0];

  if (!captionValidationErrors || captionValidationErrors.length === 0)
    return null;
  return (
    <Alert
      addon={
        <Button
          onClick={() => scrollToError(captionValidationErrors[0].lineNumber)}
          compact
          className={styles.errorButton}
          layoutOnly
          label="Click here to see errors"
        />
      }
      type={AlertType.error}
      message={`${captionValidationErrors.length} error${
        captionValidationErrors.length > 1 ? 's' : ''
      } detected`}
      bgColor="red"
      title="Validation errors"
      icon={
        <div className={styles.alertIconWrapper}>
          <ExclamationTriangleOutlined />
        </div>
      }
      enableIcon
      compact
    />
  );
};
