import React from 'react';
import * as Text from 'DesignSystem/Typography';
import { Button } from 'DesignSystem/Form';
import Document from '@tiptap/extension-document';
import CodeBlockLowlight from '@tiptap/extension-code-block-lowlight';
import CodeBlock from '@tiptap/extension-code-block';
import * as TiptapText from '@tiptap/extension-text';
import { lowlight } from 'lowlight/lib/core';
import { Box } from 'DesignSystem/Components';
import cx from 'classnames';
import {
  EditorContent,
  EditorOptions,
  NodeViewContent,
  NodeViewWrapper,
  ReactNodeViewRenderer,
  useEditor,
} from '@tiptap/react';
import { Flex } from 'DesignSystem/Layout/Flex';
import { useDelayedAction } from 'hooks/useDelayedAction';
import { ChevronDownAlt, ChevronUpAlt, Trash } from 'shared/icons';
import { useCaptionsJobs } from '../../hooks/useCaptionsJobs';
import styles from '../video.module.css';
import { CaptionValidationError } from '../../utils/caption-format-validator';

type CaptionEditorProps = {
  captionText?: string;
  validationErrors?: { [key: string]: CaptionValidationError[] };
  onReplaceClick: () => void;
  onRemoveClick: () => void;
  onCaptionTextChange?: (text: string) => void;
  canRemove?: boolean;
  disabled?: boolean;
};

export type CodeBlockProps = {
  extension: {
    storage: {
      lines: number;
      errors: CaptionValidationError[];
    };
  };
};

export const CaptionsEditor: React.FC<CaptionEditorProps> = ({
  captionText,
  validationErrors,
  onReplaceClick,
  onRemoveClick,
  onCaptionTextChange,
  canRemove,
  disabled,
}) => {
  const { isCaptionTranscriptionEnabled } = useCaptionsJobs();

  const editorTextRef = React.useRef<string>(captionText ?? '');
  const { schedule, cancel } = useDelayedAction(
    () => onCaptionTextChange && onCaptionTextChange(editorTextRef.current),
    300
  );
  const onEditorUpdate = (text: string) => {
    editorTextRef.current = text;
    cancel();
    schedule();
  };

  const editor = useEditor(
    isCaptionTranscriptionEnabled
      ? editableEditorConfig({
          text: captionText ?? '',
          onUpdate: onEditorUpdate,
          editable: !disabled,
        })
      : staticEditorConfig({
          text: captionText ?? '',
          errors:
            validationErrors && Object.values(validationErrors).length > 0
              ? Object.values(validationErrors)[0]
              : [],
        }),
    [captionText]
  );

  React.useEffect(() => {
    if (!isCaptionTranscriptionEnabled) return;

    editor?.setOptions({
      editable: !disabled,
      editorProps: {
        attributes: {
          class: cx(styles.captionsLightEditor, {
            [styles.disabled]: disabled,
          }),
        },
      },
    });
  }, [disabled, editor, isCaptionTranscriptionEnabled]);

  if (!captionText) return null;

  return (
    <Box
      color={Text.color.gray90}
      background={Text.color.gray00}
      className={cx({
        [styles.captionsEditorWrapper]: isCaptionTranscriptionEnabled,
      })}
    >
      <EditorContent editor={editor} />
      <Flex spread color={Text.background.gray05} className={styles.replace}>
        <Button
          onClick={onReplaceClick}
          disabled={disabled}
          label="Replace"
          layoutOnly
        />
        {canRemove && (
          <Button
            onClick={onRemoveClick}
            disabled={disabled}
            icon={<Trash />}
            layoutOnly
          />
        )}
      </Flex>
    </Box>
  );
};

type StaticEditorConfigProps = {
  text: string;
  errors: CaptionValidationError[];
};

const staticEditorConfig = ({
  text,
  errors,
}: StaticEditorConfigProps): Partial<EditorOptions> => ({
  editable: false,
  editorProps: {
    attributes: {
      class: styles.captionsEditor,
    },
  },
  extensions: [
    Document.extend({
      content: 'codeBlock+',
    }),
    TiptapText.Text,
    CodeBlockLowlight.extend({
      name: 'codeBlock',
      group: 'codeBlock',
      addStorage() {
        return {
          lines: text.split('\n').length,
          errors,
        };
      },
      addNodeView() {
        return ReactNodeViewRenderer(CodeBlockComponent);
      },
    }).configure({
      defaultLanguage: 'plaintext',
      exitOnArrowDown: false,
      exitOnTripleEnter: false,
      lowlight,
    }),
  ],
  content: {
    type: 'doc',
    content: [
      {
        type: 'codeBlock',
        content: [
          {
            type: 'text',
            text,
          },
        ],
      },
    ],
  },
});

type EditableEditorConfigProps = {
  text: string;
  onUpdate: (text: string) => void;
  editable: boolean;
};

const editableEditorConfig = ({
  text,
  onUpdate,
  editable,
}: EditableEditorConfigProps): Partial<EditorOptions> => ({
  editable,
  injectCSS: false,
  editorProps: {
    attributes: {
      class: cx(styles.captionsLightEditor, { [styles.disabled]: !editable }),
    },
  },

  extensions: [
    Document.extend({
      content: 'codeBlock+',
    }),
    TiptapText.Text,
    CodeBlock.extend({
      name: 'codeBlock',
      group: 'codeBlock',
      onUpdate() {
        onUpdate(this.editor.getText());
      },
    }).configure({
      exitOnArrowDown: false,
      exitOnTripleEnter: false,
    }),
  ],

  content: {
    type: 'doc',
    content: [
      {
        type: 'codeBlock',
        content: [
          {
            type: 'text',
            text,
          },
        ],
      },
    ],
  },
});

const CodeBlockComponent = (props: CodeBlockProps) => {
  const { extension } = props;
  const { storage } = extension;
  const lines = storage?.lines === undefined ? 1 : storage.lines;
  const { errors } = storage;
  const lineNumbers = CodeBlockLineNumbers(lines < 6 ? 6 : lines, errors);
  return (
    <NodeViewWrapper className="code-block">
      <Flex start alignStart className={styles.subtitlesEditorWrapper}>
        <pre id="code-block-line-numbers" className={styles.lineNumbers}>
          {lineNumbers}
        </pre>
        <pre id="code-block-content" className={styles.subtitlesWrapper}>
          <NodeViewContent as="code" />
        </pre>
      </Flex>
    </NodeViewWrapper>
  );
};

const CodeBlockLineNumbers = (
  lines: number,
  errors: CaptionValidationError[]
): JSX.Element[] => {
  const newLines: JSX.Element[] = [];
  const lineNumbers = errors.map((e) => e.lineNumber as number);

  const lineHasError = (ln: number) => {
    return lineNumbers.includes(ln);
  };

  const hasErrorsBefore = (ln: number) => {
    return lineNumbers.indexOf(ln) > 0;
  };

  const hasErrorsAfter = React.useCallback(
    (ln: number) => {
      return lineNumbers.indexOf(ln) < lineNumbers.length - 1;
    },
    [lineNumbers]
  );

  const scrollToPreviousError = (i: number) => {
    if (hasErrorsBefore(i)) {
      const prevError = errors
        .slice()
        .reverse()
        .find((e) => e.lineNumber < i);
      if (prevError) {
        const line = document.getElementById(
          `codeline-id-${prevError.lineNumber}`
        );
        if (line) {
          line.scrollIntoView();
        }
      }
    }
  };

  const scrollToNextError = (i: number) => {
    if (hasErrorsAfter(i)) {
      const nextError = errors.slice().find((e) => e.lineNumber > i);
      if (nextError) {
        const line = document.getElementById(
          `codeline-id-${nextError.lineNumber}`
        );
        if (line) {
          line.scrollIntoView();
        }
      }
    }
  };

  for (let i = 1; i <= lines; i += 1) {
    const codeNode: JSX.Element = (
      <code
        className={cx(styles.lineNumberItem, {
          [styles.subtitleError]: lineHasError(i),
        })}
        id={`codeline-id-${i}`}
        key={`codeline-key-${i}`}
      >
        {lineHasError(i) && (
          <Flex spread className={styles.subtitlesLineError}>
            <span>{errors.find((e) => e.lineNumber === i)?.message}</span>
            <Flex spread className={styles.buttonsWrapper}>
              <Button
                minimal
                layoutOnly
                disabled={!hasErrorsBefore(i)}
                className={cx(
                  {
                    [styles.navigationButtonDisabled]: !hasErrorsBefore(i),
                  },
                  styles.subtitleErrorsNavigationButton
                )}
                onClick={() => scrollToPreviousError(i)}
                icon={<ChevronUpAlt />}
              />
              <Button
                className={cx(
                  {
                    [styles.navigationButtonDisabled]: !hasErrorsAfter(i),
                  },
                  styles.subtitleErrorsNavigationButton
                )}
                minimal
                disabled={!hasErrorsAfter(i)}
                layoutOnly
                onClick={() => scrollToNextError(i)}
                icon={<ChevronDownAlt />}
              />
            </Flex>
          </Flex>
        )}
        <>{i}</>
      </code>
    );
    newLines.push(codeNode);
  }

  return newLines;
};
