import React, { createContext, useContext } from 'react';
import { navigate, useLocation } from '@reach/router';
import { QueryError } from 'hooks/common';
import { LoadingScreen } from 'shared/LoadingScreen';
import { DEFAULT_ERROR_MESSAGE } from 'models/flash-message';
import { defaultTemplate, Filter, Template } from 'models/library';
import { DefinitionBlock } from 'models/publisher/block';
import {
  useLibrary,
  useLibraryMutation,
  useTemplateQuery,
} from 'hooks/useLibrary';
import { TemplateContainerType, TemplateType } from 'models/template';
import { useHistory } from 'contexts/history';
import { useFlashMessage } from './flasher';
import { useUser } from './user';
import { Layout } from '../models/publisher/format';

export function useInitialTemplate(): TemplateType | undefined {
  const location = useLocation();

  const locationState = location?.state as {
    initialTemplate: TemplateType | undefined;
  };

  const initialTemplateRef = React.useRef<TemplateType | undefined>();

  if (locationState?.initialTemplate && !initialTemplateRef.current) {
    initialTemplateRef.current = locationState.initialTemplate;
  }

  return initialTemplateRef.current;
}

type TemplateContextData = {
  id: number | 'new';
  source?: 'library' | 'template' | unknown;
  template: Template;
  update: (changes: Partial<Template>) => void;
  save: () => void; // persists the updated value
  isWorking: boolean; // pending updates
  error?: string;
  initialisedFromPost: boolean;
};

const contextPrototype: TemplateContextData = {
  id: 'new',
  template: { ...defaultTemplate },
  update: () => {},
  save: () => {},
  isWorking: false,
  initialisedFromPost: false,
};

const TemplateContext = createContext(contextPrototype);

export const useTemplate = (): typeof contextPrototype => {
  const context = useContext(TemplateContext);
  if (context === undefined) {
    throw new Error(
      'Template context hooks require a containing TemplateProvider'
    );
  }
  return context;
};

export const TemplateProvider: React.FC<{
  programId: number;
  source?: 'library' | 'template' | unknown;
  id?: 'new' | string | unknown;
  initialValue?: Template;
  containerType?: TemplateContainerType;
}> = ({
  children,
  programId,
  id,
  source,
  initialValue = defaultTemplate,
  containerType = TemplateContainerType.Template,
}) => {
  const [template, setTemplate] = React.useState<Template>(initialValue);
  const [hasLoaded, setHasLoaded] = React.useState<boolean>();
  const initialTemplate = useInitialTemplate();
  const initialisedFromPost = Boolean(initialTemplate);

  const { setFlashMessage } = useFlashMessage();
  const user = useUser();

  const onSuccess = React.useCallback(() => {
    setFlashMessage({
      severity: 'info',
      message: `Template has been ${id === 'new' ? 'created' : 'updated'}`,
    });
    navigate(`/${programId}/app/library/templates`);
  }, [id, programId, setFlashMessage]);

  const onError = React.useCallback(
    (e: QueryError & { name?: string }) => {
      const isValidationError = e.name === 'ValidationError';
      let errMsg = DEFAULT_ERROR_MESSAGE;

      if (isValidationError) {
        try {
          const parsedError = JSON.parse(e.message);
          const messages = Array.isArray(parsedError.errors)
            ? parsedError.errors
            : [parsedError.errors];

          if (!template.title) {
            errMsg = 'Name is required';
          } else if (messages.length > 0) {
            errMsg = messages.join('. ') || '';
          }
        } catch {
          errMsg = 'An unexpected error occurred.';
        }
      }

      setFlashMessage({
        severity: 'error',
        message: errMsg,
        details: isValidationError ? '' : e.message,
      });
    },
    [setFlashMessage, template.title]
  );

  const onFetchSuccess = React.useCallback((data: Template) => {
    setTemplate(data);
  }, []);

  const { mutate: save, isSaving } = useLibraryMutation({
    onSuccess,
    onError,
  });

  const history = useHistory();
  const failureCallback = React.useCallback(() => {
    const redirectUrl = '/app/library/templates';
    setFlashMessage({
      severity: 'error',
      message: 'Template not found',
    });
    navigate(history.previous([redirectUrl]));
  }, [setFlashMessage, history]);

  const { mutate: load, isLoading } = useTemplateQuery(
    onFetchSuccess,
    containerType,
    failureCallback
  );

  const isWorking = isSaving || isLoading;

  const updateTemplate = React.useCallback(
    (changes: Partial<Template>) => {
      const updatedTemplate = {
        ...(template || defaultTemplate),
        ...changes,
      };

      setTemplate(updatedTemplate);
    },
    [template]
  );

  const saveTemplate = React.useCallback(() => {
    if (!template) return;

    // TODO: Define typing for createdBy user and remove casting.
    // Seems like it shouldn't have accessibleBrand, accesiblePrograms, and superAdmin
    if (!template.asset.template.createdBy) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const createdBy = { ...user } as any;
      // this is too much information
      createdBy.accessibleBrands = undefined;
      createdBy.accessiblePrograms = undefined;
      createdBy.superAdmin = undefined;
      template.asset.template.createdBy = createdBy;
    }

    save({
      item: template,
      programId,
    });
  }, [template, save, programId, user]);

  const template_id = source === 'template' ? Number(id) : NaN;
  React.useEffect(() => {
    if (source !== 'template') return;
    if (isWorking) return;
    if (!template_id) {
      setHasLoaded(true);
      return;
    }

    if (!template && !hasLoaded && template_id) {
      setHasLoaded(true);
      load(template_id);
    }

    if (template && Number(template.id) !== template_id) {
      setHasLoaded(true);
      load(template_id);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [template_id, template, load, source]);

  const [libraryTemplate, setLibraryTemplate] = React.useState<
    Template | undefined
  >();
  const filter: Filter = { type: 'search', scope: 'global', search: '' };
  const library = useLibrary({
    filter,
  });
  const library_id: string = source === 'library' ? (id as string) || '' : '';
  React.useEffect(() => {
    if (
      library_id &&
      source === 'library' &&
      Object.keys(library).length &&
      !libraryTemplate
    ) {
      let blocks = library[library_id]?.asset?.blocks || [];

      // If the library template is a custom html template,
      // we need to set its layout to full width. This will
      // be persisted on the content block, and will set the
      // publisher to fullHtml mode.
      if (library_id === 'custom_html')
        blocks = blocks.map((block) =>
          block.name === 'custom_html'
            ? { ...block, format_data: { layout: Layout.FullWidth } }
            : block
        );

      setLibraryTemplate({
        ...defaultTemplate,
        asset: {
          ...defaultTemplate.asset,
          template: {
            ...defaultTemplate.asset.template,
            blocks: (blocks as unknown) as DefinitionBlock[],
          },
        },
      });
    }
  }, [source, library_id, library, libraryTemplate]);

  if (template_id && Number(template.id) !== template_id)
    return <LoadingScreen />;
  if (!libraryTemplate && library_id) return <LoadingScreen />;

  const value: TemplateContextData = {
    id: template_id,
    source,
    template:
      library_id && libraryTemplate
        ? libraryTemplate
        : template || defaultTemplate,
    update: updateTemplate,
    save: saveTemplate,
    isWorking,
    error: '',
    initialisedFromPost,
  };
  return (
    <TemplateContext.Provider value={value}>
      {children}
    </TemplateContext.Provider>
  );
};
