import React, { useState } from 'react';
import cx from 'classnames';
import { Box } from 'DesignSystem/Components';
import { Button } from 'DesignSystem/Form';
import {
  isLinksFieldDataArray,
  isLinksImageProcessing,
} from 'models/publisher/block';
import { MailSquared } from 'shared/icons';
import { useSettings } from 'contexts/publisher/orchestrate/use-settings';
import { usePublisher } from 'contexts/publisher';
import { Targets } from 'models/donkey';
import { HoverTooltip } from 'shared/HoverTooltip';
import { Tooltip } from 'shared/Tooltip';
import styles from './send-test-campaign.module.css';
import { SendTestModal } from '../../../../../../../components/delivery/send-test/send-test-modal';
import { useSendTestCampaignSettings } from '../../../../../../../components/delivery/send-test/hooks';
import { useNotificationCenterEnabled } from '../../../../../../../hooks/notification-center';

export const SendTestCampaign: React.FC = () => {
  const {
    post: { blocks },
    status,
    isProcessing,
  } = usePublisher();
  const {
    contentPermissions: { canEdit },
    settings: {
      deliveryChannels: {
        email: hasEmailChannel,
        push: hasPushChannel,
        assistant: hasAssistantChannel,
      },
    },
  } = useSettings();
  const sendTestCampaignSettings = useSendTestCampaignSettings();
  const notificationCenterEnabled = useNotificationCenterEnabled();

  const [showTestCampaignModal, setShowTestCampaignModal] = useState(false);
  const visibleBlocksForEmail = blocks.filter(
    (block) =>
      block?.target === undefined ||
      !block?.target.excluded.includes(Targets.email)
  ).length;

  if (visibleBlocksForEmail < 1) {
    return null;
  }

  const blockHasProcessingLinkImage = () =>
    blocks.some((block) => {
      if (
        block.name === 'links' &&
        isLinksFieldDataArray(block.field_data?.links)
      ) {
        return isLinksImageProcessing(block.field_data.links);
      }
      return false;
    });

  return (
    <>
      {canEdit && (
        <SendTestCampaignButton
          disabled={
            blocks.length === 0 ||
            (isProcessing && !blockHasProcessingLinkImage) ||
            status.isSaving
          }
          processing={isProcessing}
          onClick={() => setShowTestCampaignModal(!showTestCampaignModal)}
        />
      )}
      {showTestCampaignModal && (
        <SendTestModal
          settings={sendTestCampaignSettings}
          initialCheckedChannels={{
            email: hasEmailChannel,
            push: hasPushChannel,
            ...(notificationCenterEnabled
              ? { notification_center: hasAssistantChannel }
              : {}),
          }}
          toggleShow={() => setShowTestCampaignModal(!showTestCampaignModal)}
        />
      )}
    </>
  );
};

export const SendTestCampaignButton: React.FC<{
  disabled: boolean;
  processing: boolean;
  onClick: () => void;
  label?: string;
}> = ({ disabled, processing, onClick, label }) => {
  const button = (
    <Button
      data-testid="preview-send-test-campaign-button"
      className={styles.Button}
      compact
      secondary
      disabled={disabled}
      onClick={onClick}
      label={
        <>
          <MailSquared />
          <span className={styles.ButtonText}>
            {label || 'Send Test Campaign'}
          </span>
        </>
      }
    />
  );

  return (
    <Box
      margin={[16, 0, 0, 0]}
      className={cx({
        [styles.processing]: processing,
      })}
    >
      {processing && disabled ? (
        <HoverTooltip
          align="left"
          content={
            <Tooltip description="This button is disabled until image processing has completed." />
          }
          icon={button}
        />
      ) : (
        button
      )}
    </Box>
  );
};
